import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { CFormInput, CInputGroup, CInputGroupText, CButton } from '@coreui/react'
import CoverLayout from 'src/StudentLayouts/authentication/components/CoverLayout'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function SetPassword() {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')
  const [token, setToken] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    // Extract token from URL
    const searchParams = new URLSearchParams(window.location.search)
    const tokenFromUrl = searchParams.get('token')
    setToken(tokenFromUrl)
    console.log('token From Url', tokenFromUrl)
  }, [])

  const validatePassword = () => {
    if (!password) {
      setPasswordError('Password is required')
      return false
    }
    return true
  }

  const validateConfirmPassword = () => {
    if (!confirmPassword) {
      setConfirmPasswordError('Confirm Password is required')
      return false
    } else if (confirmPassword !== password) {
      setConfirmPasswordError('Passwords do not match')
      return false
    }
    return true
  }

  const handleSetPassword = async (e) => {
    e.preventDefault()

    const isPasswordValid = validatePassword()
    const isConfirmPasswordValid = validateConfirmPassword()

    if (isPasswordValid && isConfirmPasswordValid) {
      try {
        setLoading(true)
        const response = await axios.post(
          'https://soft.recipe4foodies.com/api/Account/Set-Password',
          {
            password: password,
            token: token,
          },
        )

        if (response.status === 200) {
          navigate('/login', {
            state: {
              successMessage: 'Password Set Successfully Please login',
            },
          })
        } else {
          toast.error('Failed to set Password', {
            autoClose: 5000,
            style: { background: 'lightcoral', color: 'white' }, // Light red background color
          })
        }
      } catch (error) {
        console.error('OOPS:', error.message)
        if (error.response && error.response.data) {
          console.error('Error Details:', error.response.data)
        }
        alert('OOPS')
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <>
      <ToastContainer />
      <CoverLayout>
        <div className="card" style={{ width: '18rem', marginTop: '-10px' }}>
          <div className="card-body">
            <form onSubmit={handleSetPassword}>
              <div className="mb-2">
                <label className="form-label" style={{ fontWeight: 'bold' }}>
                  New Password
                </label>
                <CInputGroup>
                  <CFormInput
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    autoComplete="new-password"
                    value={password}
                    className="form-control"
                    onChange={(e) => {
                      setPassword(e.target.value)
                      setPasswordError('')
                    }}
                  />
                  <CInputGroupText
                    style={{ cursor: 'pointer', height: '37px' }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <i
                      className={showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}
                      aria-hidden="true"
                    ></i>
                  </CInputGroupText>
                </CInputGroup>
                {passwordError && (
                  <div style={{ color: 'red', fontSize: 'medium', marginTop: '-20px' }}>
                    {passwordError}
                  </div>
                )}
              </div>
              <div className="mb-2">
                <label className="form-label" style={{ fontWeight: 'bold' }}>
                  Confirm Password
                </label>
                <CInputGroup>
                  <CFormInput
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    className="form-control"
                    onChange={(e) => {
                      setConfirmPassword(e.target.value)
                      setConfirmPasswordError('')
                    }}
                  />
                  <CInputGroupText
                    style={{ cursor: 'pointer', height: '37px' }}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    <i
                      className={showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}
                      aria-hidden="true"
                    ></i>
                  </CInputGroupText>
                </CInputGroup>
                {confirmPasswordError && (
                  <div style={{ color: 'red', fontSize: 'medium', marginTop: '-20px' }}>
                    {confirmPasswordError}
                  </div>
                )}
              </div>
              <div
                className="mt-4 mb-1"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <CButton
                  color="info"
                  style={{ flex: 1, marginRight: '5px' }}
                  fullWidth
                  onClick={handleSetPassword}
                >
                  {loading ? 'Please Wait...' : 'Set Password'}
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CButton>
              </div>
            </form>
          </div>
        </div>
      </CoverLayout>
    </>
  )
}

export default SetPassword
