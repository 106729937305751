// import React from 'react'
// import { Table } from 'antd'
// import 'antd/dist/antd'
// import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
// import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'

// export default function Laptopapplication() {
//   const dataSourcequiz = [
//     {
//       key: '1',
//       ApplicationID: 5,
//       StudentID: 8,
//       Reason: 'A+',
//       Action: 'A+',
//     },
//     {
//       key: '1',
//       ApplicationID: 5,
//       StudentID: 8,
//       Reason: 'A+',
//       Action: 'A+',
//     },
//   ]

//   const columns = [
//     {
//       title: 'Application ID',
//       dataIndex: 'ApplicationID',
//       key: 'ApplicationID',
//       sorter: (a, b) => a.AssignmentID - b.AssignmentID,
//     },
//     {
//       title: 'Student ID',
//       dataIndex: 'StudentID',
//       key: 'StudentID',
//       sorter: (a, b) => a.NumbersGained - b.NumbersGained,
//     },
//     {
//       title: 'Reason',
//       dataIndex: 'Reason',
//       key: 'Reason',
//       sorter: (a, b) => a.Total - b.Total,
//     },
//     {
//       title: 'Action',
//       dataIndex: 'Action',
//       key: 'Action',
//     },
//   ]
//   return (
//     <>
//       <DashboardLayout>
//         <div className="wrapper d-flex flex-column min-vh-100 bg-light">
//           <DashboardNavbar />
//           <div className="body flex-grow-1 px-3">
//             <div className="card">
//               <div className="card-header" style={{ position: 'relative' }}>
//                 <b>Applications Lists</b>
//               </div>
//               <div className="card-body">
//                 <Table
//                   className="table-responsive"
//                   bordered
//                   // dataSource={dataSourcequiz}
//                   columns={columns}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </DashboardLayout>
//     </>
//   )
// }

import React from 'react'
import DashboardLayout from 'src/StudentNavLayouts/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/StudentNavLayouts/Navbars/DashboardNavbar'

function UnderConstruction() {
  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3 d-flex justify-content-center align-items-center">
          <div className="card">
            <div className="card-body text-center">
              <h1>Under Construction</h1>
              <p>This page is currently under construction. Please check back later.</p>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default UnderConstruction
