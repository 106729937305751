import React, { useEffect, useState } from 'react'
import { Table, Modal, Button, Tooltip, Spin, Alert, Input, message } from 'antd'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const AppliedUniversity = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const collegeApplicationId = location.state?.collegeApplicationId
  const token = localStorage.getItem('token')
  const [dataSource, setDataSource] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(30)
  const [loading, setLoading] = useState(true)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isfeedbackModalVisible, setIsFeedbackModalVisible] = useState(false)
  const [selectedUniversity, setSelectedUniversity] = useState(null)
  const [applicationStatus, setApplicationStatus] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [feedback, setFeedback] = useState('')
  const [error, setError] = useState('')
  const [isFeedbackLoading, setIsFeedbackLoading] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState('white')

  const handleInputChange = (e) => {
    setFeedback(e.target.value)
    setError('') // Clear error message on input
    setBackgroundColor('white') // Reset background color
  }

  useEffect(() => {
    if (collegeApplicationId) {
      fetchAppliedUniversities()
    }
  }, [collegeApplicationId, token])

  const fetchAppliedUniversities = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/CollegeApplication/Get-Student-Application-Detail?collegeApplicationId=${collegeApplicationId}`,
        {
          headers: {
            accept: '*/*',
            Authorization: `Bearer ${token}`,
          },
        },
      )

      const filteredData = response.data.appliedUniversities.filter(
        (university) => university.collegeApplicationId === collegeApplicationId,
      )

      setDataSource(filteredData)
      setApplicationStatus(response.data.status)
    } catch (error) {
      console.error('Error fetching applied universities:', error)
      message.error('Error fetching applied universities.')
    } finally {
      setLoading(false)
    }
  }

  const showModal = (record) => {
    setSelectedUniversity(record)
    setIsModalVisible(true)
  }
  const showFeedbackModal = (record) => {
    setSelectedUniversity(record)
    setIsFeedbackModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setIsFeedbackModalVisible(false)
    setSelectedUniversity(null)
    setFeedback('')
    setError('')
    setBackgroundColor('white')
  }

  const handleBack = () => {
    navigate('/College-Application')
  }

  const handleMarkAsViewed = async () => {
    setIsLoading(true)
    try {
      await axios.post(
        `https://soft.recipe4foodies.com/api/CollegeApplication/Mark-As-Reviewed?collegeApplicationId=${collegeApplicationId}`,
        {},
        {
          headers: {
            accept: '*/*',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      toast.success('Successfully Reviewed')
      fetchAppliedUniversities()
    } catch (error) {
      console.error('Error marking as viewed:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmitFeedback = async () => {
    // Validate feedback
    if (!feedback) {
      setError('Feedback is required')
      setBackgroundColor('rgba(255, 0, 0, 0.1)')
      return
    }

    // Validate selected university
    if (!selectedUniversity || !selectedUniversity.studentAppliedUniversityId) {
      setError('University ID is required')
      setBackgroundColor('rgba(255, 0, 0, 0.1)')
      return
    }

    const trimmedFeedback = feedback.trim()
    setIsFeedbackLoading(true) // Show loading state

    try {
      const response = await axios.post(
        'https://soft.recipe4foodies.com/api/CollegeApplication/Give-Feedback',
        {
          studentAppliedUniversityId: selectedUniversity.studentAppliedUniversityId,
          feedback: trimmedFeedback,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      toast.success('Feedback submitted successfully')

      // Reset feedback input and error state
      setFeedback('')
      setError('')
      setBackgroundColor('white')

      // Fetch updated data
      fetchAppliedUniversities() // Make sure this is awaited if it returns a promise
      handleCancel() // Close or reset the feedback form
    } catch (error) {
      console.error('Error submitting feedback:', error)
      if (error.response) {
        console.error('Error response:', error.response.data) // Log response data
        toast.error(
          'Error submitting feedback: ' +
            (error.response.data.errors?.feedback?.[0] || 'Unknown error'),
        )
      } else {
        toast.error('Error submitting feedback: ' + error.message)
      }
    } finally {
      setIsFeedbackLoading(false) // Hide loading state
    }
  }

  const modalContent = selectedUniversity ? (
    <div style={{ maxHeight: '400px', overflowY: 'auto', paddingRight: '10px' }}>
      <div style={{ marginTop: '20px' }}>
        {/* Conditional rendering based on isFeedBackGiven */}
        {!selectedUniversity.isFeedBackGiven ? (
          <>
            <Input.TextArea
              rows={4}
              value={feedback}
              onChange={handleInputChange}
              placeholder="Provide Feedback"
              style={{
                marginBottom: '10px',
                backgroundColor: backgroundColor,
              }}
            />
            {error && <span style={{ color: 'red', fontSize: 'medium' }}>{error}</span>}
            <br />
            <Button type="primary" disabled={isFeedbackLoading} onClick={handleSubmitFeedback}>
              {isFeedbackLoading ? 'Please Wait...' : 'Submit'}
            </Button>
            <hr />
          </>
        ) : (
          <p>
            <strong>Feedback:</strong> {selectedUniversity.feedBack}
          </p>
        )}
      </div>
      <p>
        <strong>Early Admission:</strong> ${selectedUniversity.earlyAdmission}
      </p>
      <p>
        <strong>Application Fee:</strong> ${selectedUniversity.applicationFee}
      </p>
      <p>
        <strong>Term:</strong> {selectedUniversity.term}
      </p>
      <p>
        <strong>FIFSA Financials:</strong> {selectedUniversity.fIFSAFinancials}
      </p>
      <p>
        <strong>Scholarships:</strong> {selectedUniversity.grantsScholarships}
      </p>
      <p>
        <strong>SAT Score:</strong> {selectedUniversity.sATScore}
      </p>
      <p>
        <strong>Number of Essays:</strong> {selectedUniversity.noOfEssays}
      </p>
      <p>
        <strong>Rounds:</strong> {selectedUniversity.applicationRounds}
      </p>
      <p>
        <strong>Decision Time:</strong>{' '}
        {new Date(selectedUniversity.decisionTime).toLocaleDateString()}
      </p>
      <p>
        <strong>Deadline:</strong> {new Date(selectedUniversity.deadline).toLocaleDateString()}
      </p>
      <p>
        <strong>Applied Date:</strong>{' '}
        {new Date(selectedUniversity.appliedDate).toLocaleDateString()}
      </p>
      <p>
        <strong>University Form Documents:</strong>
        {selectedUniversity.universityFormDocumentUrl ? (
          <a
            href={`https://soft.recipe4foodies.com${selectedUniversity.universityFormDocumentUrl}`}
            download
          >
            {' '}
            Download DOCX
          </a>
        ) : (
          'No document available'
        )}
      </p>
    </div>
  ) : null

  const feedbackmodalContent = selectedUniversity ? (
    <div style={{ maxHeight: '400px', overflowY: 'auto', paddingRight: '10px' }}>
      <div style={{ marginTop: '20px' }}>
        <p>
          <strong>Feedback:</strong> {selectedUniversity.feedBack}
        </p>
      </div>
    </div>
  ) : null

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <ToastContainer />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div
              className="card-header"
              style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <b>Applied University</b>
              <Button type="primary" onClick={handleBack} style={{ marginLeft: 'auto' }}>
                Back
              </Button>
            </div>

            <div className="card-body">
              {applicationStatus === 'Submitted' && (
                <Alert
                  message="Mark as Viewed"
                  type="warning"
                  showIcon
                  action={
                    <Button onClick={handleMarkAsViewed} disabled={isLoading}>
                      {isLoading ? 'Please Wait...' : 'Mark as Viewed'}
                    </Button>
                  }
                  style={{
                    width: '40%',
                    margin: '0 auto',
                    left: '20%',
                    transform: 'translateX(-50%)',
                    zIndex: 1000,
                  }}
                />
              )}

              {applicationStatus === 'Reviewed' && (
                <Alert
                  message="Open to view more and provide feedback."
                  type="info"
                  showIcon
                  style={{
                    width: '40%',
                    margin: '0 auto',
                    left: '20%',
                    transform: 'translateX(-50%)',
                    zIndex: 1000,
                  }}
                />
              )}

              <div style={{ padding: '20px' }}>
                <div style={{ overflowX: 'auto' }}>
                  {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                      <Spin size="large" />
                    </div>
                  ) : (
                    <Table
                      pagination={{
                        current: currentPage,
                        pageSize: pageSize,
                        onChange: (page, size) => {
                          setCurrentPage(page)
                          setPageSize(size)
                        },
                      }}
                      dataSource={dataSource}
                      columns={[
                        {
                          title: (
                            <Tooltip title="College Application Id">
                              <span>ID</span>
                            </Tooltip>
                          ),
                          dataIndex: 'collegeApplicationId',
                          width: '5%',
                        },
                        {
                          title: 'Name',
                          dataIndex: 'universityName',
                          width: '45%',
                          render: (text, record) => (
                            <div style={{ height: '40px' }}>
                              <div>{text}</div>
                              <small style={{ color: 'gray' }}>
                                <i className="fa fa-map-marker" aria-hidden="true"></i>
                                &nbsp;&nbsp;
                                {record.universityState}, United States
                              </small>
                            </div>
                          ),
                        },
                        {
                          title: 'Application_fee',
                          dataIndex: 'formOfApplicationSubmission',
                          width: '15%',
                        },
                        {
                          title: 'FeedBack_Given',
                          dataIndex: 'isFeedBackGiven',
                          width: '15%',
                          render: (isFeedBackGiven, record) =>
                            isFeedBackGiven ? (
                              <Button size="small" onClick={() => showFeedbackModal(record)}>
                                See Feedback
                              </Button>
                            ) : (
                              'N/A'
                            ),
                        },

                        {
                          title: 'View More',
                          dataIndex: 'viewMore',
                          render: (text, record) => (
                            <Button size="small" onClick={() => showModal(record)}>
                              View More
                            </Button>
                          ),
                        },
                      ]}
                    />
                  )}
                </div>
              </div>

              <Modal
                title="Applied University Details"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
              >
                {modalContent}
              </Modal>
              <Modal
                title="Your Provided Feedback"
                visible={isfeedbackModalVisible}
                onCancel={handleCancel}
                footer={null}
              >
                {feedbackmodalContent}
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default AppliedUniversity
