import React, { useState, useEffect } from 'react'
import { Button, notification, Tooltip, Tag, Rate, message } from 'antd'
import 'antd/dist/antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'

function Service() {
  const userId = localStorage.getItem('userId') || ''
  const [students, setStudents] = useState([])
  const [courses, setCourses] = useState([])
  const [selectedStudentIds, setSelectedStudentIds] = useState([])
  const [selectedCourseId, setSelectedCourseId] = useState('')
  const [selectedClassType, setSelectedClassType] = useState('')
  const token = localStorage.getItem('token')
  const [tutors, setTutors] = useState([])
  const [tutorsAvailability, setTutorsAvailability] = useState([])

  // State for managing modal visibility and selected availability record
  const [showModal, setShowModal] = useState(false)
  const [selectedAvailability, setSelectedAvailability] = useState({})
  const [bookingResponse, setBookingResponse] = useState({})

  // State for conditional rendering
  const [visibleTable, setVisibleTable] = useState('findRecord') // 'findRecord', 'tutorList', 'availabilityDates'

  useEffect(() => {
    fetchStudents()
    fetchCourses()
  }, [])

  const fetchStudents = () => {
    fetch(
      `https://soft.recipe4foodies.com/api/Session/Get-Counselor-Students?counselorId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
      .then((response) => response.json())
      .then((data) => {
        setStudents(data)
      })
      .catch((error) => console.error('Error fetching students:', error))
  }

  const fetchCourses = () => {
    fetch('https://soft.recipe4foodies.com/api/Course/Get-Course-List', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCourses(data)
      })
      .catch((error) => console.error('Error fetching courses:', error))
  }

  const fetchTutors = (courseName) => {
    fetch(`https://soft.recipe4foodies.com/api/Session/Get-TutorList?Subject=${courseName}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setTutors(data)
        setVisibleTable('tutorList')
      })
      .catch((error) => console.error('Error fetching tutors:', error))
  }

  const fetchTutorsSchedule = (event, tutorId) => {
    event.preventDefault()
    fetch(
      `https://soft.recipe4foodies.com/api/AvailabilityTime/ Tutor/Get-Two-Weeks-AvailableDates?tutorId=${tutorId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
      .then((response) => response.json())
      .then((data) => {
        console.log('Fetched Availability:', data) // Add logging here
        setTutorsAvailability(data)
        setVisibleTable('availabilityDates')
      })
      .catch((error) => console.error('Error fetching tutors availability:', error))
  }

  const handleStudentChange = (value) => {
    if (selectedClassType === '1' && value.length > 1) {
      notification.error({
        message: 'Selection Error',
        description: 'Only one student can be selected for 1 on 1 Class.',
      })
      return
    }
    if (selectedClassType === '2' && value.length > 4) {
      notification.error({
        message: 'Selection Error',
        description: 'Exactly four students must be selected for Group Class.',
      })
      return
    }
    setSelectedStudentIds(value)
  }

  const handleCourseChange = (e) => {
    setSelectedCourseId(e.target.value)
  }

  const handleClassTypeChange = (e) => {
    setSelectedClassType(e.target.value)
    setSelectedStudentIds([])
  }

  const handleSearch = () => {
    if (!selectedClassType) {
      message.error('Please select a Class Type.')
      return
    }
    if (selectedClassType === '1' && selectedStudentIds.length !== 1) {
      message.error('Please select exactly one student for 1 on 1 Class.')
      return
    }

    if (selectedClassType === '2' && selectedStudentIds.length !== 4) {
      message.error('Please select exactly four students for Group Class.')
      return
    }

    if (!selectedCourseId) {
      message.error('Please select a Subject.')
      return
    }
    if (selectedCourseId) {
      if (selectedClassType === '1' && selectedStudentIds.length !== 1) {
        notification.error({
          message: 'Selection Error',
          description: 'Please select exactly one student for 1 on 1 Class.',
        })
        return
      }
      if (selectedClassType === '2' && selectedStudentIds.length !== 4) {
        notification.error({
          message: 'Selection Error',
          description: 'Please select exactly four students for Group Class.',
        })
        return
      }
      fetchTutors(selectedCourseId)
    }
  }

  const handleBookClassClick = async (record) => {
    setSelectedAvailability(record)

    // Ensure timeId is valid
    const validTimeId = tutorsAvailability?.timeId || record?.timeId || null

    if (!validTimeId) {
      notification.error({
        message: 'Invalid Time ID',
        description: 'Time ID cannot be 0 or null. Please select a valid time slot.',
      })
      return
    }

    const payload = {
      timeId: validTimeId, // Ensure this is a valid integer
      counselorId: parseInt(userId) || 0, // Ensure this is a valid integer
      tutorId: parseInt(record?.userId) || 0, // Ensure this is a valid integer
      subject: selectedCourseId || 'string', // Ensure this is a valid string
      sessionMode: selectedClassType === '1' ? 'Single' : 'Group', // Ensure this is a valid string
      studentIdList:
        selectedStudentIds.length > 0 ? selectedStudentIds.map((id) => parseInt(id)) : [0], // Ensure list is not empty and contains integers
    }

    console.log('Payload:', payload) // Log payload for debugging

    try {
      const response = await axios.post(
        'https://soft.recipe4foodies.com/api/Session/Create-Session-info',
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Ensure the token is valid
          },
        },
      )

      setBookingResponse(response.data)
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message)
      notification.error({
        message: 'Booking Failed',
        description:
          error.response?.data?.message ||
          'There was an error booking the class. Please try again.',
      })
    }

    handleOpenModal() // Open the modal after booking attempt
  }

  const handleModalClose = () => {
    setShowModal(false)
    setSelectedAvailability(null)
  }

  const handleConfirmBooking = async () => {
    if (!selectedAvailability) {
      // Check if availability is selected
      notification.error({
        message: 'No Availability Selected',
        description: 'Please select an availability slot to book.',
      })
      return
    }

    try {
      console.log('timeId:', tutorsAvailability.timeId) // Log the timeId to check its value
      console.log('userId:', selectedAvailability.userId) // Log the timeId to check its value
      const response = await axios.post(
        'https://soft.recipe4foodies.com/api/Session/Create-TutorSession',
        {
          timeId: selectedAvailability.timeId, // Use timeId from the selected availability
          counselorId: userId,
          tutorId: selectedAvailability.userId,
          subject: selectedCourseId,
          sessionMode: selectedClassType === '1' ? 'Single' : 'Group',
          sessionDateTime: selectedAvailability.availableTime,
          studentIdList: selectedStudentIds.map((id) => parseInt(id)),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      setBookingResponse(response.data) // Store response data in state

      notification.success({
        message: 'Booking Confirmed',
        description: 'Class booked successfully!',
      })

      handleModalClose() // Close modal
    } catch (error) {
      console.error('Error:', error.response || error.message)
      notification.error({
        message: 'Booking Failed',
        description: 'There was an error booking the class.',
      })
    }
  }

  const modifiedTutorsData = tutors.map((tutor) => ({
    ...tutor,
    key: tutor.tutorId, // Assuming tutorId is unique and can be used as the key
    fullName: `${tutor.firstName} ${tutor.lastName}`, // Combine firstName and lastName into a single field
  }))

  const columnsTutors = [
    {
      title: 'Tutor ID',
      dataIndex: 'tutorId',
      key: 'tutorId',
    },
    {
      title: 'Tutor Name',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Time Zone',
      dataIndex: 'timeZone',
      key: 'timeZone',
    },
    {
      title: 'Preferred Subject 1',
      dataIndex: 'preferredSubject1',
      key: 'preferredSubject1',
    },
    {
      title: 'Preferred Subject 2',
      dataIndex: 'preferredSubject2',
      key: 'preferredSubject2',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      render: (priority) => (
        <span>
          {priority ? (
            <FontAwesomeIcon icon={faCircle} style={{ color: 'green' }} />
          ) : (
            <FontAwesomeIcon icon={faCircle} style={{ color: 'red' }} />
          )}
        </span>
      ),
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      render: (rating) => {
        if (rating === -1 || rating === 0) {
          return <Tag color="warning">No Rating</Tag>
        }
        return <Rate disabled defaultValue={rating} />
      },
    },
    {
      title: 'Action',
      dataIndex: 'tutorId',
      key: 'Action',
      render: (tutorId) => {
        return (
          <Button
            type="primary"
            onClick={(event) => fetchTutorsSchedule(event, tutorId)}
            style={{ display: 'block', backgroundColor: '#0d6efd' }}
          >
            Get Schedule
          </Button>
        )
      },
    },
  ]

  const columnsAvailability = [
    {
      title: 'Tutor ID',
      dataIndex: 'userId',
      key: 'userId',
    },
    {
      title: 'Available Local-Time',
      dataIndex: 'availableTime',
      key: 'availableTime',
      render: (text) => new Date(text).toLocaleString(),
    },

    {
      title: 'Available US-Time',
      dataIndex: 'availableTimeUSTimeZone',
      key: 'availableTimeUSTimeZone',
      render: (text, record) => {
        const date = new Date(text)
        const formattedDate = date.toLocaleDateString('en-US', {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
        })

        const formattedTime = date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })
        const fullTimezone = record.timeZone
        const timezoneAbbreviation = fullTimezone.match(/\(([^)]+)\)/)[1]
        return (
          <Tooltip title={fullTimezone}>
            {`${formattedDate}, ${formattedTime} (${timezoneAbbreviation})`}
          </Tooltip>
        )
      },
    },

    {
      title: 'Book Class',
      key: 'Book Class',
      render: (record) => {
        return record.isReserved ? (
          <p style={{ color: 'green' }}>Booked</p>
        ) : (
          <Button
            type="primary"
            onClick={() => handleBookClassClick(record)}
            style={{ display: 'block', backgroundColor: '#0d6efd' }}
          >
            Book Session
          </Button>
        )
      },
    },
  ]
  const studentsColumns = [
    {
      title: 'Student ID',
      dataIndex: 'studentId',
      key: 'studentId',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Time Zone',
      dataIndex: 'timeZone',
      key: 'timeZone',
      render: (text) => (text ? text : 'N/A'),
    },
    {
      title: 'Local Time',
      dataIndex: 'localTime',
      key: 'localTime',
      render: (text) => new Date(text).toLocaleString(),
    },
  ]

  const [isNavbarVisible, setIsNavbarVisible] = useState(true)

  const handleOpenModal = () => {
    setIsNavbarVisible(false)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setIsNavbarVisible(true)
    setShowModal(false)
  }

  return {
    visibleTable,
    handleStudentChange,
    handleCourseChange,
    handleClassTypeChange,
    handleCloseModal,
    isNavbarVisible,
    studentsColumns,
    columnsAvailability,
    columnsTutors,
    modifiedTutorsData,
    handleConfirmBooking,
    handleSearch,
    bookingResponse,
    courses,
    students,
    showModal,
    selectedClassType,
    selectedCourseId,
    setVisibleTable,
    tutorsAvailability,
    fetchTutors,
  }
}

export default Service
