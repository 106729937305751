// src/services/recommendationsService.js

import axios from 'axios'

const BASE_URL = 'https://soft.recipe4foodies.com/api'

// Function to get recommendations by counselor ID
export const getRecommendations = async (counselorId, token) => {
  return axios.get(`${BASE_URL}/SessionRecommend/Counselor?counselorId=${counselorId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// Function to fetch counselor students
export const getCounselorStudents = async (counselorId, token) => {
  return axios.get(`${BASE_URL}/Session/Get-Counselor-Students?counselorId=${counselorId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// Function to fetch courses
export const getCourses = async () => {
  return axios.get(`${BASE_URL}/Course/Get-Course-List`)
}

// Function to submit a recommendation
export const submitRecommendation = async (payload, token) => {
  return axios.post(`${BASE_URL}/SessionRecommend/Counselor`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}
