import React, { useEffect } from 'react'

// react-router-dom components
import { useLocation } from 'react-router-dom'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// @mui material components
import Box from '@mui/material/Box'

function PageLayout({ background, children }) {
  const { pathname } = useLocation()

  useEffect(() => {
    // Implement any layout-related side effects here if necessary
  }, [pathname])

  return (
    <Box
      width="100vw"
      height="100%"
      minHeight="100vh"
      bgcolor={background}
      sx={{ overflowX: 'hidden' }}
    >
      {children}
    </Box>
  )
}

// Setting default values for the props for PageLayout
PageLayout.defaultProps = {
  background: 'default',
}

// Typechecking props for the PageLayout
PageLayout.propTypes = {
  background: PropTypes.oneOf(['white', 'light', 'default']),
  children: PropTypes.node.isRequired,
}

export default PageLayout
