import React, { useEffect, useState } from 'react'
import axios from 'axios'

// @mui material components
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import SoftBox from 'src/components/SoftBox'
import SoftTypography from 'src/components/SoftTypography'
import Table from 'src/examples/Tables/Table'
import Improving from 'src/assets/images/improve.jpg'
import Sustaining from 'src/assets/images/sustaining.jpg'
import Declining from 'src/assets/images/declining.jpg'
import SoftAvatar from 'src/components/SoftAvatar'

const GroupStudents = () => {
  const userId = localStorage.getItem('userId') || ''
  const token = localStorage.getItem('token')
  const headers = {}
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://soft.recipe4foodies.com/api/StudentProgress/Counselor-Group?counselorId=${userId}`,
          {
            headers: headers,
          },
        )
        const students = response.data
        const statusCount = students.reduce((acc, student) => {
          if (!acc[student.status]) {
            acc[student.status] = 0
          }
          acc[student.status]++
          return acc
        }, {})

        const formattedData = [
          { status: 'Improving', count: statusCount.Improving || 0, image: Improving },
          { status: 'Sustaining', count: statusCount.Sustaining || 0, image: Sustaining },
          { status: 'Declining', count: statusCount.Declining || 0, image: Declining },
        ]

        setData(formattedData)
      } catch (error) {
        setError('Error fetching data')
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  if (loading) {
    return <p>Loading...</p>
  }

  if (error) {
    return <p>{error}</p>
  }

  const columns = [
    { name: 'status', align: 'left' },
    { name: 'totalStudents', align: 'center' },
  ]

  const rows = data.map((item) => ({
    status: (
      <SoftBox display="flex" alignItems="center">
        <SoftAvatar src={item.image} alt={item.status} size="s" sx={{ marginRight: 1 }} />
        <SoftTypography variant="caption" color="text" fontWeight="medium">
          {item.status}
        </SoftTypography>
      </SoftBox>
    ),
    totalStudents: (
      <SoftTypography variant="caption" color="text" fontWeight="medium">
        {item.count}
      </SoftTypography>
    ),
  }))

  return (
    <SoftBox py={3}>
      <SoftBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <SoftTypography variant="h6" gutterBottom>
                  Group Students
                </SoftTypography>
              </SoftBox>
              <SoftBox
                sx={{
                  '& .MuiTableRow-root:not(:last-child)': {
                    '& td': {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                <Table columns={columns} rows={rows} />
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  )
}

export default GroupStudents
