import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilBook,
  cilCalendar,
  cilClock,
  cilFile,
  cilInfo,
  cilNotes,
  cilPaperPlane,
  cilPeople,
  cilUser,
  cilWalk,
} from '@coreui/icons'

import CounselorDashboard from 'src/layouts/dashboard/index'
import OneonOneStudent from 'src/layouts/oneononeStudents'
import Groupstudents from 'src/layouts/GroupStudents'
import Session from 'src/layouts/Sessions/CounselorSessions/Sessions'

import Shop from './examples/Icons/Shop'
import Document from './examples/Icons/Document'
import Notes from './layouts/Notes/notes'
import LaptopApplication from './layouts/LaptopApplication/laptopapplication'
import Calender from './layouts/Calender/calender'
import Students from './layouts/Students/students'
import Recommendations from './layouts/Recomendation/Recomendation'
import UpdateSchedule from './layouts/UpdateSchedule/UpdateSchedule'
import Syllabus from './layouts/Syllabus/Syllabus'
import IntakeInterview from './layouts/IntakeInterview/intakeInterview'
import StudentPathway from './layouts/StudentPathway/studentPathway'
import StudentSubjects from './layouts/StudentSubjects/Studentsubjects'
import NewStudents from 'src/layouts/newStudents/newStudents'
import CounselorSession from './layouts/CounselorSession/CreateSession'
import TutorSessions from './layouts/TutorSession/TutorSession'
import StudentPathways from './layouts/StudentPathway/StudentPathways'
import RequestedSession from './layouts/Sessions/RequestedSessions/requestedSession'
import ParentCollegeApplication from './layouts/CollegeApplication/CollegeParentFile'
import StudentInternship from './layouts/StudentInternship/StudentInternship'

const routes = [
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'counselorsdashboard',
    route: '/counselordashboard',
    icon: <Shop size="12px" />,
    component: <CounselorDashboard />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'New Students',
    key: 'newStudents',
    route: '/newStudents',
    icon: <CIcon size="12px" icon={cilUser} />,
    component: <NewStudents />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Students',
    key: 'students',
    route: '/students',
    icon: <CIcon size="12px" icon={cilUser} />,
    component: <Students />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Recommendations',
    key: 'recommendations',
    route: '/recommendations',
    icon: <Document size="12px" />,
    component: <Recommendations />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: '1 on 1 Student',
    key: '1on1Student',
    route: '/1on1Student',
    icon: <CIcon size="12px" icon={cilUser} />,
    component: <OneonOneStudent />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Group Students',
    key: 'GroupStudents',
    route: '/GroupStudents',
    icon: <CIcon size="12px" icon={cilPeople} />,
    component: <Groupstudents />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Sessions',
    key: 'session',
    route: '/session',
    icon: <CIcon size="12px" icon={cilPeople} />,
    component: <Session />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Counselor Sessions',
    key: 'counselorSession',
    route: '/counselorSession',
    icon: <CIcon size="12px" icon={cilPeople} />,
    component: <CounselorSession />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Requested Sessions',
    key: 'requestedSession',
    route: '/requestedSession',
    icon: <CIcon size="12px" icon={cilPeople} />,
    component: <RequestedSession />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Tutor Sessions',
    key: 'tutorSessions',
    route: '/tutorSessions',
    icon: <CIcon size="12px" icon={cilPeople} />,
    component: <TutorSessions />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Notes',
    key: 'notes',
    route: '/notes',
    icon: <CIcon size="12px" icon={cilNotes} />,
    component: <Notes />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Calender',
    key: 'calender',
    route: '/calender',
    icon: <CIcon size="12px" icon={cilCalendar} />,
    component: <Calender />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Laptop Applications',
    key: 'laptopApplications',
    route: '/laptopApplications',
    icon: <CIcon size="12px" icon={cilPaperPlane} />,
    component: <LaptopApplication />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Update Schedule',
    key: 'updateSchedule',
    route: '/updateSchedule',
    icon: <CIcon size="12px" icon={cilClock} />,
    component: <UpdateSchedule />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Syllabus',
    key: 'syllabus',
    route: '/syllabus',
    icon: <CIcon size="12px" icon={cilFile} />,
    component: <Syllabus />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Intake Interview',
    key: 'intakeInterview',
    route: '/intakeInterview',
    icon: <CIcon size="12px" icon={cilInfo} />,
    component: <IntakeInterview />,
    noCollapse: true,
  },
  // {
  //   type: 'collapse',
  //   name: 'Student Pathway',
  //   key: 'studentPathway',
  //   route: '/studentPathway',
  //   icon: <CIcon size="12px" icon={cilWalk} />,
  //   component: <StudentPathway />,
  //   noCollapse: true,
  // },
  {
    type: 'collapse',
    name: 'Student Pathway',
    key: 'studentPathway',
    route: '/studentPathway',
    icon: <CIcon size="12px" icon={cilWalk} />,
    component: <StudentPathways />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Student Subjects',
    key: 'studentSubjects',
    route: '/studentSubjects',
    icon: <CIcon size="12px" icon={cilBook} />,
    component: <StudentSubjects />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'College Application',
    key: 'College Application',
    route: '/College-Application',
    icon: <CIcon size="12px" icon={cilBook} />,
    component: <ParentCollegeApplication />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Student Internship',
    key: 'Student-Internship',
    route: '/Student-Internship',
    icon: <CIcon size="12px" icon={cilBook} />,
    component: <StudentInternship />,
    noCollapse: true,
  },
]

export default routes
