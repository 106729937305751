import React from 'react'
import 'antd/dist/antd'
import { grade12courses } from './grade12courses'

export default function Grade12() {
  return (
    <>
      <div className="row">
        {/* AP Courses */}
        <div className="col-md-3">
          <strong>
            <h2
              style={{
                fontFamily: 'cursive',
                color: '#6666ff',
                textAlign: 'center',
                fontSize: '18px',
                lineHeight: '3',
              }}
            >
              AP
            </h2>
          </strong>
          <ul style={{ fontSize: '15px' }}>
            {grade12courses.AP.map((course, index) => (
              <li key={index}>{course}</li>
            ))}
          </ul>
        </div>

        {/* Honors Courses */}
        <div className="col-md-3">
          <strong>
            <h2
              style={{
                fontFamily: 'cursive',
                color: '#6666ff',
                fontSize: '18px',
                textAlign: 'center',
                lineHeight: '3',
              }}
            >
              Honors
            </h2>
          </strong>
          <ul style={{ fontSize: '15px' }}>
            {grade12courses.Honors.map((course, index) => (
              <li key={index}>{course}</li>
            ))}
          </ul>
        </div>

        {/* Academic Courses */}
        <div className="col-md-3">
          <strong>
            <h2
              style={{
                fontFamily: 'cursive',
                color: '#6666ff',
                fontSize: '18px',
                textAlign: 'center',
                lineHeight: '3',
              }}
            >
              Academic
            </h2>
          </strong>
          <ul style={{ fontSize: '15px' }}>
            {grade12courses.Academic.map((course, index) => (
              <li key={index}>{course}</li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}
