import React, { useState, useEffect } from 'react'
import { Button, Table, Collapse } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'

const { Panel } = Collapse

const formatDate = (dateString) => {
  const date = new Date(dateString)
  return date.toLocaleString()
}

export default function StudentNotes() {
  const { studentId } = useParams()
  const token = localStorage.getItem('token')
  const [resultData, setResultData] = useState([])
  const [loading, setLoading] = useState(false)
  const [activeNotes, setActiveNotes] = useState([]) // Manage multiple active panels
  const [showTable, setShowTable] = useState(true) // State to toggle between table and collapse view
  const navigate = useNavigate()

  useEffect(() => {
    if (token && studentId) {
      handleGetNotes(studentId)
    }
  }, [token, studentId])

  const handleGetNotes = async (studentId) => {
    try {
      setLoading(true)
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/Note/Student-Notes?studentId=${studentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      setResultData(response.data)
    } catch (error) {
      console.error('Error fetching notes:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleNoteClick = (noteId) => {
    // Set the clicked note as active and hide the table
    setActiveNotes([noteId]) // Set the clicked note as the active one
    setShowTable(false) // Hide the table and show collapse
  }

  const handleBackToTable = () => {
    setShowTable(true) // Show the table again
    setActiveNotes([]) // Reset active notes
  }

  const handleCollapseChange = (keys) => {
    setActiveNotes(keys) // Update the active keys based on user interaction
  }

  const columns = [
    {
      title: 'Notes ID',
      dataIndex: 'noteId',
      key: 'noteId',
    },
    {
      title: 'Student ID',
      dataIndex: 'studentId',
      key: 'studentId',
    },
    {
      title: 'Author ID',
      dataIndex: 'autherId',
      key: 'autherId',
    },
    {
      title: 'Author',
      dataIndex: 'autherPerson',
      key: 'autherPerson',
    },
    {
      title: 'Date Time',
      dataIndex: 'dateTime',
      key: 'dateTime',
      render: (text) => formatDate(text),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <span
          style={{ cursor: 'pointer', color: 'blue' }}
          onClick={() => handleNoteClick(record.noteId)}
        >
          Content
        </span>
      ),
    },
  ]

  const backToStd = () => {
    navigate('/students')
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div
              className="card-header"
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <b>{showTable ? 'Notes' : 'Notes Content'}</b>
              <Button type="primary" onClick={backToStd}>
                Back to Students
              </Button>
            </div>

            <div className="card-body">
              {showTable ? (
                // Display the table when showTable is true
                <Table
                  className="table-responsive"
                  dataSource={resultData}
                  columns={columns}
                  loading={loading}
                  bordered
                  rowKey="noteId"
                />
              ) : (
                // Display the collapsible panels when showTable is false
                <>
                  <Button onClick={handleBackToTable} style={{ marginBottom: '16px' }}>
                    Back to Table
                  </Button>
                  <Collapse activeKey={activeNotes} onChange={handleCollapseChange}>
                    {resultData.map((note) => (
                      <Panel header={`Note ID: ${note.noteId}`} key={note.noteId}>
                        <p>
                          <b>Student ID:</b> {note.studentId}
                        </p>
                        <p>
                          <b>Content:</b> {note.content || 'No content available'}
                        </p>
                      </Panel>
                    ))}
                  </Collapse>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}

// import React, { useState, useEffect } from 'react'
// import { Button, Table } from 'antd'
// import { useNavigate, useParams } from 'react-router-dom'
// import axios from 'axios'
// import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
// import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'

// const formatDate = (dateString) => {
//   const date = new Date(dateString)
//   return date.toLocaleString()
// }

// export default function StudentNotes() {
//   const { studentId } = useParams()
//   const token = localStorage.getItem('token')
//   const [resultData, setResultData] = useState([])
//   const [loading, setLoading] = useState(false)
//   const [viewingNote, setViewingNote] = useState(null)
//   const navigate = useNavigate()

//   useEffect(() => {
//     if (token && studentId) {
//       handleGetNotes(studentId)
//     }
//   }, [token, studentId])

//   const handleGetNotes = async (studentId) => {
//     try {
//       setLoading(true)
//       const response = await axios.get(
//         `https://soft.recipe4foodies.com/api/Note/Student-Notes?studentId=${studentId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         },
//       )
//       setResultData(response.data)
//     } catch (error) {
//       console.error('Error fetching notes:', error)
//     } finally {
//       setLoading(false)
//     }
//   }

//   const columns = [
//     {
//       title: 'Notes ID',
//       dataIndex: 'noteId',
//       key: 'noteId',
//     },
//     {
//       title: 'Student ID',
//       dataIndex: 'studentId',
//       key: 'studentId',
//     },
//     {
//       title: 'Author ID',
//       dataIndex: 'autherId',
//       key: 'autherId',
//     },
//     {
//       title: 'Author',
//       dataIndex: 'autherPerson',
//       key: 'autherPerson',
//     },
//     {
//       title: 'Date Time',
//       dataIndex: 'dateTime',
//       key: 'dateTime',
//       render: (text) => formatDate(text),
//     },
//     {
//       title: 'Actions',
//       key: 'actions',
//       render: (text, record) => (
//         <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => setViewingNote(record)}>
//           Content
//         </span>
//       ),
//     },
//   ]

//   const backToStd = () => {
//     navigate('/students')
//   }

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <div className="wrapper d-flex flex-column min-vh-100 bg-light">
//         <div className="body flex-grow-1 px-3">
//           <div className="card">
//             <div
//               className="card-header"
//               style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
//             >
//               <b>{viewingNote ? 'Note Detail' : 'Notes'}</b>
//               <Button type="primary" onClick={backToStd}>
//                 Back to Students
//               </Button>
//             </div>

//             <div className="card-body">
//               {viewingNote ? (
//                 <div>
//                   <div>
//                     <span style={{ fontSize: 'medium' }}>
//                       <b>ID:</b> {viewingNote.studentId}
//                     </span>
//                   </div>
//                   <div>
//                     <span style={{ fontSize: 'medium' }}>
//                       <b>Content:</b> {viewingNote.content || 'No content available'}
//                     </span>
//                   </div>
//                   <br />
//                   <Button onClick={() => setViewingNote(null)}>Back to Table</Button>
//                 </div>
//               ) : (
//                 <>
//                   <Table
//                     className="table-responsive"
//                     dataSource={resultData}
//                     columns={columns}
//                     loading={loading}
//                     bordered
//                     rowKey="noteId"
//                   />
//                 </>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </DashboardLayout>
//   )
// }
