//Grade9Courses.js
export const grade11courses = {
  AP: [
    'AP Lang',
    'AP Physics',
    'AP Environmental Science',
    'AP Biology',
    'AP Physics C Mech.',
    'AP Physics C E and M',
    'AP United States History',
  ],
  Honors: [
    'Anatomy/Physiology Honors',
    'Research Methods Honors',
    'American History Honors',
    'English III Honors',
  ],
  Academic: [
    'Anatomy/Physiology',
    'Forensic Science',
    'American History',
    'PEPI',
    'PEPI II',
    'English III Honors',
    'Interior Design Fundamentals (I)',
  ],
}
