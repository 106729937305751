// Update your store.js file
import { createStore, combineReducers } from 'redux'
import authReducer from '../src/auth/authReducer' // Update the path accordingly

const rootReducer = combineReducers({
  auth: authReducer,
  // Add other reducers as needed
})

const store = createStore(rootReducer)
export default store

// import { createStore } from 'redux'

// const initialState = {
//   sidebarShow: true,
// }

// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return { ...state, ...rest }
//     default:
//       return state
//   }
// }

// const store = createStore(changeState)
// export default store
