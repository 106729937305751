//Grade9Courses.js
export const grade10Courses = {
  AP: [
    'AP Calculus AB',
    'AP Calculus BC',
    'AP Statistics',
    'AP Art History',
    'AP Studio Art: 3D',
    'AP Studio Art: 2D Drawing',
    'AP Studio Art: 2D Design',
    'AP Music Theory',
    'AP Computer Science A',
    'AP Computer Science Principles',
    'AP African American Studies',
    'AP Psychology',
    'AP Human Geography',
    'AP European History',
    'AP US Government & Politics',
    'AP Chem Semester',
    'AP Language',
  ],
  Honors: [
    'NC Math 1 Honor',
    'NC Math 2 Honors',
    'NC Math 3 Honors',
    'NC Math 4 Honors',
    'Spanish III Honors',
    'Spanish IV Honors',
    'Spanish V Honors',
    'English 2 Honors',
    'Creative Writing 1 Honors',
    'Creative Writing 2 Honors',
    'Speech 1 Honors',
    'Speech 2 Honors',
    'Newspaper II Honors & Leadership in Media I Honors',
    'Discrete Math for Comp Sci Honors',
    'Project Management I Honors',
    'Project Management II Honors',
    'Accounting I Honors',
    'Accounting II Honors',
    'Entrepreneurship I Honors',
    'Entrepreneurship II Honors',
    'Adobe Visual Design I Honors',
    'Holocaust & Genocide Studies Honors',
    'Psychology Honors',
    'Sociology Honors',
    'Religions/World Cultures Honors',
    'African American Studies Honors',
    'Contemporary Law and Justice Honors',
    'Civic Literacy Honors',
    'World History Honors',
    'Earth/Environmental Science Honors',
    'Biology Honors',
    'Chemistry Honors',
  ],
  Academic: [
    'English 2',
    'Creative Writing',
    'NC Math 4',
    'Team Sports I',
    'Team Sports II',
    'Methods of Coaching',
    'Weight Training I',
    'Weight Training II',
    'Weight Training III',
    'Sports Medicine I (10/11)',
    'Sports Medicine II',
    'Visual Arts',
    'Environmental Science',
    'Personal Health & Fitness',
    'Spanish I',
    'Spanish I Heritage',
    'Spanish II',
    'Marketing',
    'Marketing II',
    'Business Essentials',
    'Project Management I',
    'Technology Engineering & Design',
    'Python Programming I',
    'Engineering Design',
  ],
  Arts: [
    'Choral Music I – Beginning',
    'Choral Music II: Bass Clef Ensemble',
    'Choral Music III: Aria Choir',
    'Choral Music III: Treble Voices',
    'Choral Music IV: Chamber Choir',
    'AP Music Theory',
    'Jazz Ensemble – Proficient Honors',
    'Band Beginning: Concert Band 1 (Fall)',
    'Band Intermediate (Spring): Concert Band 2',
    'Band Proficient (Fall): Marching Band/Wind Symphony',
    'Band Proficient (Spring): Symphonic Band',
    'Band Advanced (Spring): Wind Ensemble',
  ],
}
