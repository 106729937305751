import React, { useState, useEffect } from 'react'
import './navbar.scss'
// react-router components
import { useLocation, useNavigate } from 'react-router-dom'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// @material-ui core components
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import Icon from '@mui/material/Icon'

import SoftBox from 'src/components/SoftBox'
import SoftTypography from 'src/components/SoftTypography'

import Breadcrumbs from 'src/StudentNavLayouts/Breadcrumbs'
import NotificationItem from 'src/StudentNavLayouts/Items/NotificationItem'
import AccountItems from 'src/StudentNavLayouts/AccountItems/AccountData'
import axios from 'axios'

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from 'src/examples/Navbars/DashboardNavbar/styles'

import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from 'src/context'

// Images
import Subscription from 'src/assets/images/StudentDashboard/subs.png'
import Info from 'src/assets/images/StudentDashboard/info.png'
import logoSpotify from 'src/assets/images/p.png'
import changepassword from 'src/assets/images/chp.png'
import logout from 'src/assets/images/logOUT.png'
import pluseorder from 'src/assets/images/plus.png'
import downcloud from 'src/assets/images/clouddown.png'
import warning from 'src/assets/images/warning.jpg'
import complete from 'src/assets/images/complete.png'
import Gscript from 'src/assets/images/script.png'
import adminpic from 'src/assets/images/p.png'
import { Alert, Button, Space } from 'antd'
import { UserOutlined } from '@ant-design/icons'

function DashboardNavbar({ absolute, light, isMini }) {
  const userId = localStorage.getItem('userId') || ''
  const token = localStorage.getItem('token')
  const userRole = localStorage.getItem('role')
  const [navbarType, setNavbarType] = useState()
  const [controller, dispatch] = useSoftUIController()
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller
  const [openMenu, setOpenMenu] = useState(false)
  const [openaccountmenu, setOpenaccountmenu] = useState(false)
  const route = useLocation().pathname.split('/').slice(1)
  const location = useLocation()
  const navigate = useNavigate()
  const [studentDetails, setStudentDetails] = useState()
  const [alertText, setAlertText] = useState('You have not completed your profile yet!')
  const [showProfileButton, setShowProfileButton] = useState(true)
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    studentBasicDetails()
    if (fixedNavbar) {
      setNavbarType('sticky')
    } else {
      setNavbarType('static')
    }
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar)
    }
    window.addEventListener('scroll', handleTransparentNavbar)
    handleTransparentNavbar()
    return () => window.removeEventListener('scroll', handleTransparentNavbar)
  }, [dispatch, fixedNavbar])

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.get(
          `https://soft.recipe4foodies.com/api/Student/Profile-Edit?studentId=${userId}`,
          { headers: { Authorization: `Bearer ${token}` } },
        )

        const profileCompleted = response.data.isProfileCompleted
        const studentName = (
          <strong>
            {response.data.studentFirstName} {response.data.studentLastName}
          </strong>
        )
        if (!profileCompleted) {
          setShowAlert(true)
          if (location.pathname === '/profile') {
            setAlertText(<span>{studentName}, Please Update your profile.</span>)
            setShowProfileButton(false)
          }
        } else {
          setShowAlert(false)
          setAlertText('Your profile is not completed!')
          setShowProfileButton(true)
        }
      } catch (error) {
        console.error('Error fetching profile data:', error)
      }
    }
    fetchProfileData()
  }, [userId, token])

  const studentBasicDetails = async () => {
    try {
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/Student/Student-Basic-Detail?studentId=${userId}`,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      // setStudentDetails(response.data.studentId)
      setStudentDetails({
        id: response.data.studentId,
        image: `https://soft.recipe4foodies.com/${response.data.profileImageUrl}` || '',
      })
    } catch (error) {
      console.error('Error in getting details:', error)
    }
  }
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav)
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator)
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget)
  const handleCloseMenu = () => setOpenMenu(false)
  const handleaccount = (event) => setOpenaccountmenu(event.currentTarget)
  const handlecloseaccount = () => setOpenaccountmenu(false)

  const handleprofile = () => {
    navigate('/profile')
  }
  const handleSubscription = () => {
    navigate('/subscription')
  }
  const handlefillInformation = () => {
    navigate('/fillInformation')
  }
  const handlechangepassword = () => {
    navigate('/changeStdPassword')
  }
  const handleLogout = () => {
    localStorage.setItem('logout', true)
    localStorage.clear()
    navigate('/login')
  }

  const handleProfileClick = () => {
    navigate('/profile')
  }

  const backTomultiLogin = () => {
    navigate('/multiLogin')
  }

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
    >
      <NotificationItem
        image={<img src={pluseorder} />}
        title={['', 'You have new order!']}
        date="Are your doing meet me tonight?"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        image={<img src={downcloud} alt="person" />}
        title={['', '99% Server load']}
        date="You got new order of goods."
        onClick={handleCloseMenu}
      />
      <NotificationItem
        image={<img src={warning} alt="person" style={{ height: '36px' }} />}
        title={['', 'Warning notification']}
        date="Server have 99% CPU usage."
        onClick={handleCloseMenu}
      />
      <NotificationItem
        image={<img src={complete} alt="person" />}
        title={['', 'Complete the task']}
        date="Cake sesame snaps cupcake."
        onClick={handleCloseMenu}
      />
      <NotificationItem
        image={<img src={Gscript} alt="person" />}
        title={['', 'Generate monthly report']}
        date="Chocolate cake oat cake tiramisu marzipan."
        onClick={handleCloseMenu}
      />
    </Menu>
  )

  const account = () => (
    <Menu
      anchorEl={openaccountmenu}
      anchorReference={null}
      open={Boolean(openaccountmenu)}
      onClose={handlecloseaccount}
      sx={{ marginLeft: 'auto' }}
    >
      {/* Conditionally render items based on userRole */}
      {userRole !== 'Admin' && (
        <>
          <AccountItems
            image={<img src={logoSpotify} alt="person" />}
            title={['Profile']}
            onClick={handleprofile}
          />
          <AccountItems
            image={<img src={Info} alt="person" />}
            title={['Student Information']}
            onClick={handlefillInformation}
          />
        </>
      )}
      <AccountItems
        image={<img src={Subscription} alt="person" />}
        title={['Subscription']}
        onClick={handleSubscription}
      />
      <AccountItems
        image={<img src={changepassword} alt="person" />}
        title={['Change Password']}
        onClick={handlechangepassword}
      />
      <AccountItems
        color="secondary"
        image={<img src={logout} alt="person" />}
        title={['Logout']}
        onClick={handleLogout}
      />
    </Menu>
  )

  return (
    <>
      {showAlert && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '110vw',
              height: '10vh',
              position: 'fixed',
              top: 0,
              left: 0,
              zIndex: 9999,
            }}
          >
            <Alert
              message={alertText}
              type="warning"
              showIcon
              action={
                <Space>
                  {showProfileButton && (
                    <Button type="primary" onClick={handleProfileClick} size="small">
                      Complete Profile
                    </Button>
                  )}
                </Space>
              }
            />
          </div>
        </>
      )}

      <AppBar
        position={absolute ? 'absolute' : navbarType}
        color="inherit"
        sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
      >
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <SoftBox
            color="inherit"
            mb={{ xs: 1, md: 0 }}
            sx={(theme) => navbarRow(theme, { isMini })}
          >
            <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
          </SoftBox>

          {isMini ? null : (
            <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
              {userRole === 'Admin' && (
                <Button
                  type="primary"
                  onClick={backTomultiLogin}
                  ghost
                  style={{ marginRight: '10px' }}
                >
                  Back to MultiLogin
                </Button>
              )}
              {userRole === 'Admin' ? null : (
                <SoftBox pr={1}>
                  <span style={{ fontSize: '18px' }}>Student ID: {studentDetails?.id}</span>
                </SoftBox>
              )}
              <SoftBox pr={1}></SoftBox>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={light ? 'text-white' : 'text-dark'}>
                  {miniSidenav ? 'menu_open' : 'menu'}
                </Icon>
              </IconButton>
              {renderMenu()}
              <SoftBox color={light ? 'white' : 'inherit'}>
                <IconButton sx={navbarIconButton} size="large" onClick={handleaccount}>
                  {userRole === 'Admin' ? (
                    <img
                      src={adminpic}
                      alt="Admin Profile"
                      style={{ width: '44px', height: '44px', borderRadius: '50%' }}
                    />
                  ) : (
                    <img
                      src={studentDetails?.image || adminpic} // Use default image if studentDetails.image is not available
                      alt={studentDetails?.image ? 'Student Profile' : 'Default Student Profile'}
                      style={{ width: '44px', height: '44px', borderRadius: '50%' }}
                      onError={(e) => {
                        e.target.src = adminpic
                      }}
                    />
                  )}

                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color={light ? 'white' : 'dark'}
                  ></SoftTypography>
                </IconButton>

                {account()}
              </SoftBox>
            </SoftBox>
          )}
        </Toolbar>
      </AppBar>
    </>
  )
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
}

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
}

export default DashboardNavbar
