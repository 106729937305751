import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import 'antd/dist/antd'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'

const Courses = () => {
  const [loader, setLoader] = useState(false)
  const [addedCourses, setAddedCourses] = useState()
  const navigate = useNavigate()
  const { studentId } = useParams()
  const token = localStorage.getItem('token')

  useEffect(() => {
    if (token && studentId) {
      getData(studentId)
    }
  }, [token, studentId])

  const getData = async (studentId) => {
    try {
      setLoader(true)
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/Course/Student-Courses-List?studentId=${studentId}`,
        config,
      )
      const responseData = response.data.courses

      if (Array.isArray(responseData)) {
        setAddedCourses(responseData)
      } else {
        console.error('Data received is not an array:', responseData)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoader(false)
    }
  }

  const columns = [
    {
      title: 'Course Name',
      dataIndex: 'courseName',
      key: 'courseName',
      width: '30%',
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      width: '30%',
      render: (text) => (text === null || text === undefined || text === '' ? 'N/A' : text),
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      key: 'grade',
      render: (text) => (text === null || text === undefined || text === '' ? 'N/A' : text),
    },
  ]

  const backToStd = () => {
    navigate('/students')
  }

  return (
    <>
      <DashboardLayout>
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <DashboardNavbar />
          <div className="body flex-grow-1 px-3">
            <div className="card">
              <div className="card-header" style={{ position: 'relative' }}>
                <b>High School Courses</b>
                <button
                  type="button"
                  style={{ float: 'inline-end' }}
                  onClick={backToStd}
                  className="btn btn-outline-dark"
                >
                  Back
                </button>
              </div>
              <div className="card-body">
                <div>
                  <Table
                    className="table-responsive"
                    bordered
                    dataSource={addedCourses}
                    columns={columns}
                    loading={loader}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  )
}

export default Courses
