import React, { useState } from 'react'
import 'antd/dist/antd'
import { grade10Courses } from './grade10courses'

export default function Grade10() {
  const [showAll, setShowAll] = useState({
    AP: false,
    Honors: false,
    Academic: false,
    Arts: false,
  })

  const [activeCategory, setActiveCategory] = useState(null)

  const handleToggle = (category) => {
    if (activeCategory === category) {
      // If the clicked category is already active, toggle its state
      setShowAll((prev) => ({ ...prev, [category]: !prev[category] }))
    } else {
      // If a different category is clicked, close the previously active category and open the new one
      setShowAll((prev) => ({
        ...prev,
        [activeCategory]: false, // Close the previous category
        [category]: true, // Open the new category
      }))
      setActiveCategory(category)
    }
  }

  const renderCourseList = (courses, category, limit) => (
    <>
      <ul style={{ fontSize: '15px' }}>
        {courses.slice(0, showAll[category] ? courses.length : limit).map((course, index) => (
          <li key={index}>{course}</li>
        ))}
        {courses.length > limit && (
          <span
            onClick={() => handleToggle(category)}
            style={{
              color: 'skyblue',
              cursor: 'pointer',
              display: 'block',
              textAlign: 'center',
              margin: '10px 0',
              fontSize: '14px',
              marginTop: '1px',
            }}
          >
            {showAll[category] ? 'View Less' : 'View More'}
          </span>
        )}
      </ul>
    </>
  )

  return (
    <>
      <div className="row">
        {/* AP Courses */}
        <div className="col-md-3">
          <strong>
            <h2
              style={{
                fontFamily: 'cursive',
                color: '#6666ff',
                textAlign: 'center',
                fontSize: '18px',
                lineHeight: '3',
              }}
            >
              AP
            </h2>
          </strong>
          {renderCourseList(grade10Courses.AP, 'AP', 10)}
        </div>

        {/* Honors Courses */}
        <div className="col-md-3">
          <strong>
            <h2
              style={{
                fontFamily: 'cursive',
                color: '#6666ff',
                fontSize: '18px',
                textAlign: 'center',
                lineHeight: '3',
              }}
            >
              Honors
            </h2>
          </strong>
          {renderCourseList(grade10Courses.Honors, 'Honors', 11)}
        </div>

        {/* Academic Courses */}
        <div className="col-md-3">
          <strong>
            <h2
              style={{
                fontFamily: 'cursive',
                color: '#6666ff',
                fontSize: '18px',
                textAlign: 'center',
                lineHeight: '3',
              }}
            >
              Academic
            </h2>
          </strong>
          {renderCourseList(grade10Courses.Academic, 'Academic', 12)}
        </div>

        {/* Arts Courses */}
        <div className="col-md-3">
          <strong>
            <h2
              style={{
                fontFamily: 'cursive',
                color: '#6666ff',
                fontSize: '18px',
                textAlign: 'center',
                lineHeight: '3',
              }}
            >
              Arts
            </h2>
          </strong>
          {renderCourseList(grade10Courses.Arts, 'Arts', 7)}
        </div>
      </div>
    </>
  )
}
