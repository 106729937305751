//Grade9Courses.js
export const grade9Courses = {
  AP: [
    'AP World History: Modern',
    'AP African American Studies',
    'AP Pre-Calculus (only if had NC Math 3 in 8th grade)',
  ],
  Honors: [
    'NC Math 1 Honors',
    'NC Math 2 Honors',
    'NC Math 3 Honors',
    'NC Math 4 Honors',
    'Earth/Environmental Science Honors',
    'Biology Honors',
    'World History Honors',
    'Microsoft Word & PowerPoint Honors',
    'Microsoft Excel Honors',
    'Drafting I Honors',
    'Adobe Visual Design I Honors',
    'Design II Honors',
  ],
  Academic: [
    'English 1',
    'YA Lit Academic',
    'Speech',
    'Earth/Environmental Science',
    'Religions in World Cultures/The Bible in History',
    'African American Studies',
    'Weight Training Conditioning I',
    'Team Sports I',
    'Healthful Living 1',
    'Spanish I',
    'Spanish I Heritage',
    'Spanish II',
    'French I',
    'Visual Arts – Beginning (Art 1)',
    'Visual Arts II-Intermediate',
    'Modern Dance – Beginning',
    'Theater Arts – Beginning (Theater 1)',
    'Choral Music 1 – Beginning',
  ],
  Arts: [
    'Visual Arts I – Beginning',
    'Visual Arts II – Intermediate',
    '*Visual Arts III – Proficient Honors',
    '*Visual Arts IV – Advanced Honors',
    'Sculpture & Ceramics Intermediate',
    'Sculpture & Ceramics - Advanced Honors',
    'Art History',
    'AP Art History',
    'AP Studio Art: 3D',
    '*Independent Study – Visual Arts (Fall) & AP Studio Art: 2D Drawing',
    '*Independent Study – Visual Arts (Fall) & AP Studio Art: 2D Design',
    'Theater Arts I – Beginning',
    'Theater Arts II – Intermediate',
    '*Theater Arts III – Proficient Honors',
    '*Theater Arts IV – Advanced Honors',
    'Modern Dance I – Beginning',
    'Modern Dance II – Intermediate',
    '*Modern Dance III – Proficient Honors',
    '*Modern Dance IV',
  ],
  DualCredit: [
    'Survey Of Economics',
    'Introduction to Business',
    'Microeconomics',
    'Macroeconomics',
    'Calc III',
    'Art Appreciation',
    'Introduction to Sociology',
    'Psychology',
    'Principles of Management',
  ],
  Key: ['Pre-requisites required'],
}
