import React, { useEffect, useState } from 'react'
// @mui material components
import Grid from '@mui/material/Grid'

import SoftBox from 'src/components/SoftBox/SoftBoxRoot'

import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout/index'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar/index'
import OneOnoneStudent from 'src/layouts/dashboard/components/oneOnoneStudent'
import GroupStudents from 'src/layouts/dashboard/components/Groupstudents'
import Meetings from './components/Meeting/meeting'
import { useNavigate, useLocation } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function Dashboard() {
  const location = useLocation()
  const navigate = useNavigate()
  const [successMessage, setSuccessMessage] = useState(location.state?.successMessage)
  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage, {
        autoClose: 5000,
        style: { background: 'lightgreen', color: 'white' },
      })
      setSuccessMessage(null)
      navigate(location.pathname, { replace: true, state: {} })
    }
  }, [successMessage, navigate, location.pathname])
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Meetings />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3}>
          <Grid container spacing={3}></Grid>
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <OneOnoneStudent />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <GroupStudents />
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  )
}

export default Dashboard
