import React from 'react'
import 'antd/dist/antd'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import Grade9 from './Grade9 Courses/grade9'
import Grade10 from './Grade10Courses/grade10'
import Grade11 from './Grade11Courses/grade11'
import Grade12 from './Grade12Courses/grade12'

export default function StudentPathways() {
  return (
    <>
      <DashboardLayout>
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <DashboardNavbar />
          <div className="body flex-grow-1 px-3">
            <div className="card">
              <div className="card-header" style={{ position: 'relative' }}>
                <h5>Graduation Pathways</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label
                      className="form-label"
                      style={{
                        fontFamily: 'cursive',
                        color: '#6666ff',
                        fontSize: '20px',
                      }}
                    >
                      Option 1: Standard Pathway
                    </label>

                    <div className="controls" style={{ fontSize: '15px' }}>
                      <p>
                        This pathway is recommended for students that<br></br>plan to attend a
                        community college, join the<br></br> military ,or enter the workforce after
                        high school.{' '}
                      </p>
                      <ul>
                        <li>English Language Arts (4.0 credits)</li>
                        <li>History / Social Studies (4.0 credits)</li>
                        <li>Mathematics (3.0 credits)</li>
                        <li>Science (3.0 credits)</li>
                        <li>Fine Arts (1.0 credit)</li>
                        <li>Health and P.E. (1.0 credit)</li>
                        <li>EPractical Arts (1.0 credit)</li>
                        <li>Electives (7.0 credits)</li>
                        <br></br>
                        <br></br>
                        <li>
                          <strong
                            style={{
                              fontFamily: 'cursive',
                              fontSize: '20px',
                            }}
                          >
                            Total: 24 credits
                          </strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label
                      className="form-label"
                      style={{
                        fontFamily: 'cursive',
                        color: '#6666ff',
                        fontSize: '20px',
                      }}
                    >
                      Option 2: Honors Pathway
                    </label>
                    <div className="controls" style={{ fontSize: '15px' }}>
                      <p>
                        This pathway provides a more rigorous program of<br></br>study and is
                        recommended for students who plan to<br></br> apply to competitive four-year
                        colleges.{' '}
                      </p>
                      <ul>
                        <li>English Language Arts (4.0 credits)</li>
                        <li>History / Social Studies (4.0 credits)</li>
                        <li>Mathematics (4.0 credits)</li>
                        <li>Science (3.0 credits)</li>
                        <li>Fine Arts (1.0 credit)</li>
                        <li>Language Other Than English (2.0 credits)</li>
                        <li>Health and P.E. (1.0 credit)</li>
                        <li>Practical Arts (1.0 credit)</li>
                        <li>Electives (6.0 credits)</li>
                        <br></br>
                        <li>
                          <strong
                            style={{
                              fontFamily: 'cursive',
                              fontSize: '20px',
                            }}
                          >
                            Total: 26 credits&nbsp;(Min. GPA: 3.0)
                          </strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <strong
                    style={{
                      textAlign: 'center',
                      color: '#6666ff',
                      fontFamily: 'cursive',
                      fontSize: '25px',
                    }}
                  >
                    High School Course Catalog
                  </strong>
                  <strong>
                    <h2 style={{ textAlign: 'center', fontFamily: 'cursive', fontSize: '20px' }}>
                      9th Grade
                    </h2>
                  </strong>
                  <br></br>
                  <br></br>
                  <Grade9 />
                  <hr></hr>

                  <strong>
                    <h2 style={{ textAlign: 'center', fontFamily: 'cursive', fontSize: '20px' }}>
                      10th Grade
                    </h2>
                  </strong>
                  <br></br>
                  <br></br>
                  <Grade10 />
                  <hr></hr>

                  <strong>
                    <h2 style={{ textAlign: 'center', fontFamily: 'cursive', fontSize: '20px' }}>
                      11th Grade
                    </h2>
                  </strong>
                  <br></br>
                  <br></br>
                  <Grade11 />
                  <hr></hr>

                  <strong>
                    <h2 style={{ textAlign: 'center', fontFamily: 'cursive', fontSize: '20px' }}>
                      12th Grade
                    </h2>
                  </strong>
                  <br></br>
                  <br></br>
                  <Grade12 />
                </div>
              </div>
            </div>
            <br></br>
          </div>
        </div>
      </DashboardLayout>
    </>
  )
}
