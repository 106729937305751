import React, { useState } from 'react'
import { Table, Button, Modal, Card, Radio, Space } from 'antd'
import 'antd/dist/antd'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import Service from './Service'

const CounselorSession = () => {
  const [showExtraColumns, setShowExtraColumns] = useState(false)
  const {
    loading,
    isConductedModalVisible,
    isModalVisible,
    showDetails,
    showRequestedSessions,
    columnsToShow,
    confirmMarkAsConducted,
    handleConductedModalClose,
    handleBackButtonClick,
    handleModalClose,
    meetingData,
    selectedMeeting,
    isParticipated,
    handleParticipationChange,
    addLoading,
  } = Service(showExtraColumns)

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          {showDetails ? (
            <Card
              title="Counselor Session"
              extra={
                <Button
                  onClick={() => setShowExtraColumns((prev) => !prev)}
                  type="primary"
                  style={{ float: 'inline-end' }}
                >
                  {showExtraColumns ? 'View Less' : 'View More'}
                </Button>
              }
            >
              <Table
                className="table-responsive"
                dataSource={meetingData}
                columns={columnsToShow}
                bordered
                loading={loading}
                pagination={true}
              />
            </Card>
          ) : showRequestedSessions ? (
            <div>
              <h6>Requested Sessions Content</h6>
              <Button onClick={handleBackButtonClick}>Back</Button>
            </div>
          ) : (
            <div>
              <h6>New Screen Content</h6>
              <Button onClick={handleBackButtonClick}>Back</Button>
            </div>
          )}
          {selectedMeeting && (
            <Modal
              title="Meeting Details"
              open={isModalVisible}
              onCancel={handleModalClose}
              footer={[
                <Button key="close" onClick={handleModalClose}>
                  Close
                </Button>,
                <Button key="join" type="primary" href={selectedMeeting.startUrl} target="_blank">
                  Start Meeting
                </Button>,
              ]}
            >
              <p>
                <b>Session ID:</b> {selectedMeeting.sessionId}
              </p>
              <p>
                <b>Time:</b> {new Date(selectedMeeting.startTime).toLocaleString()}
              </p>
              <p>
                <b>Duration:</b> {selectedMeeting.duration} minutes
              </p>
              <p>
                <b>Meeting ID:</b> {selectedMeeting.meetingId}
              </p>
              <p>
                <b>Student Name:</b> {selectedMeeting.studentName}
              </p>
            </Modal>
          )}
          <Modal
            title="Mark Session As Conducted"
            open={isConductedModalVisible}
            onCancel={handleConductedModalClose}
            footer={[
              <Button key="cancel" onClick={handleConductedModalClose}>
                Cancel
              </Button>,
              <Button
                key="confirm"
                disabled={addLoading}
                type="primary"
                onClick={confirmMarkAsConducted}
              >
                {addLoading ? 'Please Wait...' : 'Mark as Conduct'}
              </Button>,
            ]}
          >
            <b>{selectedMeeting?.studentName} : &nbsp;&nbsp;</b>
            <Radio.Group onChange={handleParticipationChange} value={isParticipated}>
              <Space>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
          </Modal>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default CounselorSession
