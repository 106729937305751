import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import 'src/Common/Register.scss'
import { CFormInput, CInputGroup } from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import useRegisterService from 'src/Common/Registerservice'
import Termandcondition from '../assets/termandcondition/termandcondition.pdf'
import { Button, Tooltip } from 'antd'

const Register = () => {
  const {
    formData,
    formDataError,
    isLoading,
    handleSubmit,
    handleChange,
    handleRoleDropdownChange,
  } = useRegisterService()
  const [isRoleSelected, setIsRoleSelected] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    try {
      const success = await handleSubmit()
      if (success) {
        toast.success('Registration Successful! Confirmation email sent to you', {
          autoClose: 5000,
          style: { background: 'lightgreen', color: 'white' },
        })
      }
    } catch (error) {
      toast.error(error.message || 'Registration Error', {
        autoClose: 5000,
        style: { background: 'lightcoral', color: 'white' },
      })
    }
  }

  const handlePdfPreview = async (fileUrl) => {
    try {
      const extension = fileUrl.split('.').pop().toLowerCase()

      if (extension === 'pdf') {
        window.open(fileUrl, '_blank')
      } else {
        window.open(fileUrl, '_blank')
      }
    } catch (error) {
      console.error('Error handling file:', error)
    }
  }

  const handleRoleChange = (e) => {
    handleRoleDropdownChange(e)
    setIsRoleSelected(e.target.value !== '')
  }

  return (
    <div className="register-container">
      <div className="register-card">
        <h6 className="register-title">Role Based Registration</h6>
        <ToastContainer />
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label className="label">Select Role *</label>
            <CInputGroup className={`mb-3 ${formDataError.roleError ? 'is-invalid' : ''}`}>
              <select
                name="role"
                value={formData.role}
                onChange={handleRoleChange}
                className="form-select"
              >
                <option value="">Select Role</option>
                <option value="Tutor">I am a Tutor</option>
                <option value="Counselor">I am a Counselor</option>
              </select>
              {formDataError.roleError && (
                <div className="invalid-feedback">{formDataError.roleError}</div>
              )}
            </CInputGroup>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label className="label">First Name *</label>
              <CFormInput
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className={`form-control ${formDataError.firstNameError ? 'is-invalid' : ''}`}
                placeholder="Enter First Name"
              />
              {formDataError.firstNameError && (
                <div className="invalid-feedback">{formDataError.firstNameError}</div>
              )}
            </div>

            <div className="form-group">
              <label className="label">Middle Name</label>
              <CFormInput
                placeholder="Enter Middle name"
                name="middleName"
                value={formData.middleName}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label className="label">Last Name *</label>
              <CFormInput
                placeholder="Enter Last name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className={`form-control ${formDataError.lastNameError ? 'is-invalid' : ''}`}
              />
              {formDataError.lastNameError && (
                <div className="invalid-feedback">{formDataError.lastNameError}</div>
              )}
            </div>

            <div className="form-group">
              <label className="label">Email *</label>
              <CFormInput
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                className={`form-control ${formDataError.emailError ? 'is-invalid' : ''}`}
                onChange={handleChange}
              />
              {formDataError.emailError && (
                <div className="invalid-feedback">{formDataError.emailError}</div>
              )}
            </div>
          </div>

          <div className="form-group">
            <label className="term-condition-label">
              <input
                type="checkbox"
                className="term-condition-checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span className="label">Accept &nbsp;</span>
            </label>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                handlePdfPreview(Termandcondition)
              }}
              className="terms-link"
            >
              Terms and Conditions
            </a>
          </div>

          <div className="form-group d-flex justify-content-end">
            {isRoleSelected ? (
              <Link
                to={formData.role === 'Tutor' ? '/tutorLogin' : '/counselorLogin'}
                className="login-link"
              >
                Already have an account/{formData.role}?
              </Link>
            ) : (
              <Tooltip title="Please Select Role">
                <span className="disabled" style={{ color: 'orange', cursor: 'not-allowed' }}>
                  Already have an account?
                </span>
              </Tooltip>
            )}
          </div>

          <div className="form-group d-flex justify-content-end">
            <Button type="primary" onClick={handleSubmit} disabled={isLoading || !isChecked}>
              {isLoading ? 'Loading...' : 'Create Account'}
              {isLoading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Register
