import React, { useState, useEffect } from 'react'
import { Button, Input, Table } from 'antd'
import 'antd/dist/antd'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import { useNavigate } from 'react-router-dom'

export default function IntakeInterview() {
  const userId = localStorage.getItem('userId') || ''
  const token = localStorage.getItem('token')
  const headers = {}
  const navigate = useNavigate()
  const [resultData, setData] = useState([])

  useEffect(() => {
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }
    handleGetStudents()
  }, [])

  const handleGetStudents = () => {
    fetch(`https://soft.recipe4foodies.com/api/IntakeInterveiw/Students?counselorId=${userId}`, {
      headers: headers,
    })
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        setData(data)
      })
  }
  const columns = [
    {
      title: 'Student ID',
      dataIndex: 'studentId',
      key: 'studentId',
      sorter: (a, b) => a.AssignmentID - b.AssignmentID,
    },
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: (a, b) => a.NumbersGained - b.NumbersGained,
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      sorter: (a, b) => a.Total - b.Total,
    },
    {
      title: 'Action',
      key: 'Action',
      render: (record) => (
        <div>
          {record.isIntakeDone ? (
            <span style={{ color: 'green' }}>Viewed</span>
          ) : (
            <Button type="primary" onClick={(event) => StudentInfo(event, record.studentId)}>
              Proceed
            </Button>
          )}
        </div>
      ),
    },
  ]

  const StudentInfo = async (event, studentId) => {
    navigate('/studentFillInfo', { state: { studentId: studentId } })
  }
  return (
    <>
      <DashboardLayout>
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <DashboardNavbar />
          <div className="body flex-grow-1 px-3">
            <div className="card">
              <div className="card-header" style={{ position: 'relative' }}>
                <h5>Intake Interview</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <Table
                    className="table-responsive"
                    bordered
                    dataSource={resultData}
                    columns={columns}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  )
}
