import React, { useState, useEffect } from 'react'
import { Card, Form, Select, Button, Row, Col, message, Spin } from 'antd'
import 'antd/dist/antd' // Ensure to import Ant Design styles
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import axios from 'axios'
import './studentSubject.scss'

const { Option } = Select

export default function StudentSubjects() {
  const userId = localStorage.getItem('userId') || ''
  const token = localStorage.getItem('token')
  const headers = {}
  const [englishCoreCourses, setEnglishCoreCourses] = useState([])
  const [mathCoreCourses, setMathCoreCourses] = useState([])
  const [historyCoreCourses, setHistoryCoreCourses] = useState([])
  const [foreignLanguageCoreCourses, setForeignLanguageCoreCourses] = useState([])
  const [scienceCoreCourses, setScienceCoreCourses] = useState([])
  const [electiveCourses, setElectiveCourses] = useState([])
  const [students, setStudents] = useState([])
  const [availableYears, setAvailableYears] = useState([])
  const [coursesAdded, setCoursesAdded] = useState(false)
  const [loading, setLoading] = useState(false) // Loading state
  const [isLoading, setIsLoading] = useState(false)
  const [customMessage, setCustomMessage] = useState('')

  const [formData, setFormData] = useState({
    studentId: '',
    counselorId: userId,
    recommendCourses: [], // Array to hold selected courses
  })

  useEffect(() => {
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }
    fetchCourses('EnglishCore', setEnglishCoreCourses)
    fetchCourses('MathCore', setMathCoreCourses)
    fetchCourses('HistoryCore', setHistoryCoreCourses)
    fetchCourses('ForeignLanguageCore', setForeignLanguageCoreCourses)
    fetchCourses('ScienceCore', setScienceCoreCourses)
    fetchCourses('ElectiveSubjects', setElectiveCourses)
    fetchStudents()
  }, [])

  const fetchStudents = () => {
    fetch(
      `https://soft.recipe4foodies.com/api/Session/Get-Counselor-Students?counselorId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
      .then((response) => response.json())
      .then((data) => {
        setStudents(data)
      })
      .catch((error) => console.error('Error fetching students:', error))
  }

  const fetchCourses = async (courseType, setCourseState) => {
    try {
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/Course/CoreAndElective?name=${courseType}`,
        {
          headers: headers,
        },
      )
      setCourseState(response.data)
    } catch (error) {
      message.error('Error fetching courses')
    }
  }

  const handleCourseChange = (value, year) => {
    const course = [
      ...englishCoreCourses,
      ...mathCoreCourses,
      ...historyCoreCourses,
      ...foreignLanguageCoreCourses,
      ...scienceCoreCourses,
      ...electiveCourses,
    ].find((course) => course.courseName === value)

    if (course) {
      const updatedCourses = formData.recommendCourses.filter((c) => c.year !== year) // Remove existing entry for the same year
      updatedCourses.push({ courseId: course.courseId, courseName: course.courseName, year: year })

      setFormData({ ...formData, recommendCourses: updatedCourses })
    } else {
      console.warn('Course not found:', value) // Log a warning if the course is not found
    }
  }

  const handleSubmitClick = async () => {
    const selectedCourses = formData.recommendCourses.map((course) => ({
      courseId: course.courseId,
      year: course.year, // Ensure to include the year
    }))

    const allCoursesSelected = availableYears.every(
      (year) =>
        formData.recommendCourses.some(
          (course) => course.year.toLowerCase() === year.toLowerCase(),
        ), // Match case
    )

    if (!allCoursesSelected) {
      message.error('Please select a course for each subject.') // Validation error message
      return // Stop the function if validation fails
    }

    const payload = {
      recommendCourses: selectedCourses, // Ensure this is structured correctly
      studentId: Number(formData.studentId),
      counselorId: Number(userId),
    }

    try {
      setIsLoading(true)
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
      const response = await axios.post(
        'https://soft.recipe4foodies.com/api/Course/Add-Recommend-Courses',
        payload,
        {
          headers: headers,
        },
      )
      message.success(response.data.message)
      setCoursesAdded(true)
      setCustomMessage('New courses have been successfully added for this student.')
    } catch (error) {
      message.error('Error in submitting')
    } finally {
      setIsLoading(false)
    }
  }

  const handleStudentChange = async (studentId) => {
    setFormData({ ...formData, studentId })
    setLoading(true) // Start loading

    try {
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/Course/Get-CurrentUpcomming-Years?studentId=${studentId}`,
        { headers: { Authorization: `Bearer ${token}` } },
      )

      const { years, alreadyAdded } = response.data
      setAvailableYears(years)
      setCoursesAdded(alreadyAdded)
    } catch (error) {
      console.error('Error fetching student years:', error)
      message.error('Error fetching data for selected student')
    } finally {
      setLoading(false) // Stop loading
    }
  }

  const renderSubjectCard = (title, grade) => (
    <Card title={title} style={{ marginBottom: '20px' }}>
      <Form layout="vertical">
        <Row gutter={16}>
          {[
            {
              label: 'English Core',
              courses: englishCoreCourses,
            },
            {
              label: 'Math Core',
              courses: mathCoreCourses,
            },
            {
              label: 'History Core',
              courses: historyCoreCourses,
            },
            {
              label: 'Foreign Language Core',
              courses: foreignLanguageCoreCourses,
            },
            {
              label: 'Science Core',
              courses: scienceCoreCourses,
            },
            {
              label: 'Elective Subjects',
              courses: electiveCourses,
            },
          ].map(({ label, courses }, index) => (
            <Col span={12} key={label}>
              <Form.Item label={label}>
                <Select
                  placeholder="Please Select"
                  onChange={(value) => handleCourseChange(value, grade)}
                >
                  {courses.map((course) => (
                    <Option key={course.courseId} value={course.courseName}>
                      {course.courseName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ))}
        </Row>
      </Form>
    </Card>
  )

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <Card title="Select Student" style={{ marginBottom: '20px' }}>
            <Form.Item
              label="Student Id"
              name="studentId"
              rules={[{ required: true, message: 'Please select a student ID' }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                id="studentIds"
                style={{ width: '100%' }}
                placeholder="Select a Student Id"
                onChange={handleStudentChange}
              >
                {students.map((student) => (
                  <Option key={student.studentId} value={student.studentId}>
                    {`${student.studentId}, ${student.name}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {/* Check if loading */}
            {loading ? (
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Spin size="large" /> {/* Loader */}
              </div>
            ) : (
              <>
                {/* Check if courses have been added */}
                {coursesAdded ? (
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <span style={{ fontSize: 'medium' }}>
                      {customMessage || 'Courses have already been added for this student.'}
                    </span>
                  </div>
                ) : (
                  <>
                    {availableYears.map((year) => (
                      <div key={year}>
                        {renderSubjectCard(`${year} Subjects`, year.toLowerCase())}
                      </div>
                    ))}
                    {formData.studentId && (
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button type="primary" onClick={handleSubmitClick} disabled={isLoading}>
                          {isLoading ? 'Please Wait...' : 'Submit'}
                          {isLoading && (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </Card>
        </div>
      </div>
    </DashboardLayout>
  )
}
