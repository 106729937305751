import React, { useState } from 'react'
import 'antd/dist/antd'
import { grade9Courses } from './grade9courses'

export default function Grade9() {
  const [showAll, setShowAll] = useState({
    AP: false,
    Honors: false,
    Academic: false,
    Arts: false,
    DualCredit: false,
    Key: false,
  })

  const [activeCategory, setActiveCategory] = useState(null)

  const handleToggle = (category) => {
    if (activeCategory === category) {
      // If the clicked category is already active, toggle its state
      setShowAll((prev) => ({ ...prev, [category]: !prev[category] }))
    } else {
      // If a different category is clicked, close the previously active category and open the new one
      setShowAll((prev) => ({
        ...prev,
        [activeCategory]: false, // Close the previous category
        [category]: true, // Open the new category
      }))
      setActiveCategory(category)
    }
  }

  const renderCourseList = (courses, category, limit) => (
    <>
      <ul style={{ fontSize: '15px' }}>
        {courses.slice(0, showAll[category] ? courses.length : limit).map((course, index) => (
          <li key={index}>{course}</li>
        ))}
        {courses.length > limit && (
          <span
            onClick={() => handleToggle(category)}
            style={{
              color: 'skyblue',
              cursor: 'pointer',
              display: 'block',
              textAlign: 'center',
              margin: '10px 0',
              fontSize: '14px',
              marginTop: '1px',
            }}
          >
            {showAll[category] ? 'View Less' : 'View More'}
          </span>
        )}
      </ul>
    </>
  )

  return (
    <>
      <div className="row">
        {/* AP Courses */}
        <div className="col-md-3">
          <strong>
            <h2
              style={{
                fontFamily: 'cursive',
                color: '#6666ff',
                textAlign: 'center',
                fontSize: '18px',
                lineHeight: '3',
              }}
            >
              AP
            </h2>
          </strong>
          {renderCourseList(grade9Courses.AP, 'AP', Infinity)} {/* Show all */}
        </div>

        {/* Honors Courses */}
        <div className="col-md-3">
          <strong>
            <h2
              style={{
                fontFamily: 'cursive',
                color: '#6666ff',
                fontSize: '18px',
                textAlign: 'center',
                lineHeight: '3',
              }}
            >
              Honors
            </h2>
          </strong>
          {renderCourseList(grade9Courses.Honors, 'Honors', 10)}
        </div>

        {/* Academic Courses */}
        <div className="col-md-3">
          <strong>
            <h2
              style={{
                fontFamily: 'cursive',
                color: '#6666ff',
                fontSize: '18px',
                textAlign: 'center',
                lineHeight: '3',
              }}
            >
              Academic
            </h2>
          </strong>
          {renderCourseList(grade9Courses.Academic, 'Academic', 7)}
        </div>

        {/* Arts Courses */}
        <div className="col-md-3">
          <strong>
            <h2
              style={{
                fontFamily: 'cursive',
                color: '#6666ff',
                fontSize: '18px',
                textAlign: 'center',
                lineHeight: '3',
              }}
            >
              Arts
            </h2>
          </strong>
          {renderCourseList(grade9Courses.Arts, 'Arts', 6)}
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <strong>
            <h2
              style={{
                fontFamily: 'cursive',
                color: '#6666ff',
                textAlign: 'center',
                fontSize: '18px',
              }}
            >
              Dual Credit
            </h2>
          </strong>
          {renderCourseList(grade9Courses.DualCredit, 'DualCredit', Infinity)} {/* Show all */}
        </div>

        <div className="col-md-3">
          <strong>
            <h2
              style={{
                fontFamily: 'cursive',
                color: '#6666ff',
                fontSize: '18px',
                textAlign: 'center',
              }}
            >
              Key
            </h2>
          </strong>
          {renderCourseList(grade9Courses.Key, 'Key', Infinity)} {/* Show all */}
        </div>
      </div>
    </>
  )
}
