import React, { useState, useEffect } from 'react'
import { Button, Table, Modal, Tooltip } from 'antd'
import 'antd/dist/antd'
// import Chatbot from '../dashboard/components/Chat/Chatbot'
import DashboardLayout from 'src/TutorNavLayouts/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/TutorNavLayouts/Navbars/DashboardNavbar'
import SoftTypography from 'src/components/SoftTypography'
import zoom from 'src/assets/images/Zoom-icon.png'
import axios from 'axios'
export default function TutorSessions() {
  const userId = localStorage.getItem('userId') || ''
  const token = localStorage.getItem('token')
  const [meetingData, setMeetingData] = useState([])
  const [selectedMeeting, setSelectedMeeting] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [loading, setIsloading] = useState(false)

  useEffect(() => {
    const headers = {}
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }

    const fetchMeetings = async () => {
      try {
        setIsloading(true)
        const response = await axios.get(
          `https://soft.recipe4foodies.com/api/Session/Get-Tutor-Sessions-For-Counselor?counselorId=${userId}&upcomingOnly=true`,
          { headers },
        )
        setMeetingData(response.data)
      } catch (error) {
        console.error('Error fetching meetings:', error)
      } finally {
        setIsloading(false)
      }
    }

    fetchMeetings()
  }, [token, userId])

  const handleZoomIconClick = (record) => {
    setSelectedMeeting(record)
    setIsModalVisible(true)
  }

  const handleViewDetailsClick = (record) => {
    setSelectedMeeting(record)
    setShowDetails(true) // Show the attendees' details
  }

  const handleModalClose = () => {
    setIsModalVisible(false)
    setSelectedMeeting(null)
  }

  const handleBackToMeetingsClick = () => {
    setShowDetails(false)
    setSelectedMeeting(null)
  }

  const meetingcolumns = [
    // {
    //   title: 'S.no',
    //   dataIndex: 'studentId',
    //   key: 'studentId',
    //   render: (text, record, index) => index + 1,
    // },
    {
      title: 'Tutor Email',
      dataIndex: 'tutorEmail',
      key: 'tutorEmail',
      render: (text) => {
        const maxLength = 8
        const truncatedText = text.length > maxLength ? `${text.slice(0, maxLength)}...` : text

        return (
          <Tooltip title={text}>
            <span>{truncatedText}</span>
          </Tooltip>
        )
      },
    },
    {
      title: 'Session Type',
      dataIndex: 'sessionType',
      key: 'sessionType',
    },
    {
      title: 'Session Mode',
      dataIndex: 'sessionMode',
      key: 'sessionMode',
      // sorter: (a, b) => a.sessionMode - b.sessionMode,
    },
    {
      title: 'Time',
      dataIndex: 'startTime',
      key: 'startTime',
      render: (text) => new Date(text).toLocaleString(),
      // sorter: (a, b) => a.Time - b.Time,
    },
    {
      title: 'Subject',
      dataIndex: 'topic',
      key: 'topic',
      // sorter: (a, b) => a.Time - b.Time,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button type="link" onClick={() => handleViewDetailsClick(record)}>
          View Detail
        </Button>
      ),
    },
    {
      title: 'Link',
      dataIndex: 'startUrl',
      key: 'startUrl',
      render: (text, record) => {
        return (
          <a onClick={() => handleZoomIconClick(record)}>
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
              <img src={zoom} alt="Start Link" style={{ width: '40px', height: '40px' }} />
            </SoftTypography>
          </a>
        )
      },
      // sorter: (a, b) => a.Link - b.Link,
    },
  ]

  return (
    <div>
      <DashboardLayout>
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <DashboardNavbar />
          <div className="body flex-grow-1 px-3">
            <div className="card">
              <div className="card-header" style={{ position: 'relative' }}>
                <b>View All Meetings</b>
              </div>
              <div className="card-body">
                {showDetails && selectedMeeting ? (
                  <div>
                    <h6>Attendee Details</h6>
                    {selectedMeeting.attendees.map((attendee, index) => (
                      <div key={attendee.studentId}>
                        <div>
                          <span style={{ fontSize: '16px' }}>
                            <b>Student ID-{index + 1}:</b> {attendee.studentId}
                          </span>
                        </div>
                        <div>
                          <span style={{ fontSize: '16px' }}>
                            <b>StudentName:</b> {attendee.studentName}
                          </span>
                        </div>
                        <div>
                          <span style={{ fontSize: '16px' }}>
                            <b>Email:</b> {attendee.studentEmail}
                          </span>
                        </div>
                        <hr /> {/* Separator line */}
                      </div>
                    ))}
                    <Button onClick={handleBackToMeetingsClick}>Back to Meetings</Button>
                  </div>
                ) : (
                  <Table
                    className="table-responsive"
                    dataSource={meetingData}
                    columns={meetingcolumns}
                    bordered
                    loading={loading}
                    pagination={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
      {selectedMeeting && (
        <Modal
          title="Meeting Details"
          open={isModalVisible}
          onCancel={handleModalClose}
          footer={[
            <Button key="close" onClick={handleModalClose}>
              Close
            </Button>,
            <Button key="join" type="primary" href={selectedMeeting.startUrl} target="_blank">
              Start Meeting
            </Button>,
          ]}
        >
          <p>
            <b>Time:</b> {new Date(selectedMeeting.startTime).toLocaleString()}
          </p>
          <p>
            <b>Duration:</b> {selectedMeeting.duration} minutes
          </p>
          <p>
            <b>Meeting ID:</b> {selectedMeeting.meetingId}
          </p>
          <p>
            <b>Passcode:</b> {selectedMeeting.password}
          </p>
        </Modal>
      )}
    </div>
  )
}
