//APIS
import axios from 'axios'

const API_BASE_URL = 'https://soft.recipe4foodies.com/api/Session'

export const fetchAvailableTimes = async (userId, token) => {
  const response = await axios.get(
    `${API_BASE_URL}/Get-Two-Weeks-Counselor-AvailableDates?counselorId=${userId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
  return response.data.filter((time) => !time.isReserved)
}

export const fetchStudents = async (userId, token) => {
  const response = await fetch(`${API_BASE_URL}/Get-Counselor-Students?counselorId=${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.json()
}

export const createCounselorSession = async (formData, token) => {
  const response = await axios.post(`${API_BASE_URL}/Create-CounselorSession`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response
}
