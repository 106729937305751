import React, { useState, useEffect, createRef } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Table, Tooltip } from 'antd'
import './calender.scss'
import 'antd/dist/antd'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import { WidthFull } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

export default function Schedule() {
  const userId = localStorage.getItem('userId') || ''
  const [selectedDate, setSelectedDate] = useState(new Date())
  const token = localStorage.getItem('token')
  const [resultData, setEvents] = useState([])

  const customEventStyle = {
    backgroundColor: '#004b9c',
    color: 'white',
    borderRadius: '5px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    padding: '2px',
    fontSize: '10px',
    cursor: 'pointer',
  }
  const navigate = useNavigate()
  useEffect(() => {
    const headers = {}
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }
    fetch(
      `https://soft.recipe4foodies.com/api/Session/Get-Counselor-Sessions?counselorId=${userId}&upcomingOnly=true`,
      {
        headers: headers,
      },
    )
      .then((res) => res.json())
      .then((data) => {
        const result = data.map((session) => ({
          id: session.sessionId,
          title: session.topic,
          start: session.startTime,
          participant: session.participant,
        }))
        setEvents(result)
        console.log('Processed events:', result)
      })
      .catch((error) => console.error('Error fetching available dates:', error))
  }, [])

  const handleEventClick = (eventInfo) => {
    console.log('Event clicked:', eventInfo.event)
    navigate(`/session?id=${eventInfo.event.id}`)
  }
  const calendarRef = createRef()

  return (
    <>
      <DashboardLayout>
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <DashboardNavbar />
          <div className="body flex-grow-1 px-3">
            <div className="card">
              <div className="card-header" style={{ position: 'relative' }}>
                <h5>Calendar ha</h5>
              </div>
              <div className="card-body">
                <FullCalendar
                  ref={calendarRef}
                  plugins={[dayGridPlugin, timeGridPlugin]}
                  initialView="timeGridWeek"
                  events={resultData}
                  slotMinTime="00:00:00"
                  slotMaxTime="24:00:00"
                  slotDuration="00:15:00"
                  eventOverlap={false}
                  contentHeight="auto"
                  customButtons={{
                    myTimeDayBtn: {
                      text: 'Day',
                      click() {
                        const calendar = calendarRef.current
                        if (calendar) {
                          const calendarApi = calendar.getApi()
                          calendarApi.changeView('timeGridDay')
                        }
                      },
                    },
                    myTimeWeekBtn: {
                      text: 'Week',
                      click() {
                        const calendar = calendarRef.current
                        if (calendar) {
                          const calendarApi = calendar.getApi()
                          calendarApi.changeView('timeGridWeek')
                        }
                      },
                    },
                  }}
                  headerToolbar={{
                    left: 'prev,next',
                    center: 'title',
                    right: 'today,myTimeDayBtn,myTimeWeekBtn,dayGridMonth',
                  }}
                  eventContent={(eventInfo) => (
                    <Tooltip title={`${eventInfo.timeText} - ${eventInfo.event.title}`}>
                      <div
                        style={{ ...customEventStyle, ...eventInfo.style }}
                        onClick={() => handleEventClick(eventInfo)}
                      >
                        {eventInfo.timeText} - {eventInfo.event.title}
                      </div>
                    </Tooltip>
                  )}
                  eventDidMount={(info) => {
                    const eventEl = info.el
                    // Remove any inline styles for better control via CSS
                    eventEl.style.inset = ''
                    eventEl.style.zIndex = ''
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  )
}
