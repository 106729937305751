import React, { useEffect, useState } from 'react'
import { Modal, Button, Spin, Input, message, Card } from 'antd'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavbarVisibility } from '../../Common/NavbarVisibilty'

const EassyReview = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const essayReviewApplicationId = location.state?.essayReviewApplicationId
  const token = localStorage.getItem('token')
  const [dataSource, setDataSource] = useState([])
  const [loading, setLoading] = useState(true)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [essay, setSelectedEssay] = useState(null)
  const [applicationStatus, setApplicationStatus] = useState('')
  const { setIsNavbarVisible } = useNavbarVisibility()
  const [feedback, setFeedback] = useState('')
  const [error, setError] = useState('')
  const [isFeedbackLoading, setIsFeedbackLoading] = useState(false)

  useEffect(() => {
    if (essayReviewApplicationId) {
      fetchEassy()
    } else {
      console.error('No essayReviewApplicationId found.')
    }
  }, [essayReviewApplicationId, token])

  const fetchEassy = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/EssayApplication/Get-Application-With-Detail?essayReviewApplicationId=${essayReviewApplicationId}`,
        {
          headers: {
            accept: '*/*',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      setDataSource(response.data.studentEssays)
      setApplicationStatus(response.data.status)
    } catch (error) {
      message.error('Error fetching essay.')
    } finally {
      setLoading(false)
    }
  }

  const showModal = (record) => {
    if (record) {
      setSelectedEssay(record) // Set selected essay
      setIsModalVisible(true) // Open modal
      setIsNavbarVisible(false) // Hide navbar if needed
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setSelectedEssay(null)
    setFeedback('')
    setError('')
  }

  const handleBack = () => {
    navigate(-1)
  }

  const handleInputChange = (e) => {
    setFeedback(e.target.value)
    setError('') // Clear error message on input
  }

  const handleSubmitFeedback = async () => {
    const reviewId = essay?.studentEssayId

    if (!feedback) {
      setError('Feedback is required')
      return
    }

    if (!reviewId) {
      setError('Essay Review ID is required')
      return
    }

    const trimmedFeedback = feedback.trim()
    setIsFeedbackLoading(true)

    try {
      await axios.post(
        'https://soft.recipe4foodies.com/api/EssayApplication/Give-Feedback',
        {
          studentEssayId: reviewId,
          feedback: trimmedFeedback,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      toast.success('Feedback submitted successfully')
      fetchEassy()
      handleCancel()
    } catch (error) {
      console.error('Error submitting feedback:', error)
      toast.error('Error submitting feedback.')
    } finally {
      setIsFeedbackLoading(false)
    }
  }

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <ToastContainer />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div
              className="card-header"
              style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <b>Essay Review</b>
              <Button type="primary" onClick={handleBack} style={{ marginLeft: 'auto' }}>
                Back
              </Button>
            </div>

            <div className="card-body">
              {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                  <Spin size="large" />
                </div>
              ) : (
                <div style={{ padding: '20px' }}>
                  {dataSource.length === 0 ? (
                    <p>No essays available.</p>
                  ) : (
                    dataSource.map((essay) => (
                      <Card
                        key={essay.studentEssayId}
                        title={`Essay ID: ${essay.essayId}`}
                        extra={
                          <a
                            href={`https://soft.recipe4foodies.com${essay.contentFileUrl}`}
                            download
                          >
                            Download DOCX
                          </a>
                        }
                        style={{ marginBottom: '20px' }}
                      >
                        <p>
                          <strong>Prompt:</strong> {essay.prompt || 'No prompt available'}
                        </p>
                        {/* Conditional rendering based on application status */}
                        {applicationStatus === 'Submitted' && !essay.isFeedbackGiven ? (
                          <Button onClick={() => showModal(essay)} type="primary">
                            Give Feedback
                          </Button>
                        ) : (
                          <p>
                            <strong style={{ color: 'orange' }}>
                              Feedback submitted successfully.
                            </strong>{' '}
                          </p>
                        )}
                      </Card>
                    ))
                  )}
                </div>
              )}

              <Modal
                title="Essay Details"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
              >
                {essay && (
                  <div style={{ maxHeight: '400px', overflowY: 'auto', paddingRight: '10px' }}>
                    <div style={{ marginTop: '20px' }}>
                      {!essay.isFeedbackGiven ? (
                        <>
                          <Input.TextArea
                            rows={4}
                            value={feedback}
                            onChange={handleInputChange}
                            placeholder="Provide Feedback"
                            style={{ marginBottom: '10px' }}
                          />
                          {error && <span style={{ color: 'red' }}>{error}</span>}
                          <br />
                          <Button
                            type="primary"
                            disabled={isFeedbackLoading}
                            onClick={handleSubmitFeedback}
                          >
                            {isFeedbackLoading ? 'Please Wait...' : 'Submit'}
                          </Button>
                          <hr />
                        </>
                      ) : (
                        <p>
                          <strong>Feedback:</strong> {essay.feedback}
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default EassyReview
