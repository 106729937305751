// useCounselorSession.js
import { useState, useEffect } from 'react'
import { message } from 'antd'
import {
  fetchAvailableTimes as fetchAvailableTimesFromAPI,
  fetchStudents as fetchStudentsFromAPI,
  createCounselorSession,
} from './API.js'

const useCounselorSession = (userId, token, initialStudentId) => {
  const [formData, setFormData] = useState({
    studentId: initialStudentId,
    counselorId: userId,
    timeId: '',
  })
  const [availableTimes, setAvailableTimes] = useState([])
  const [students, setStudents] = useState([])
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (userId && token) {
      fetchAvailableTimes()
      fetchStudents()
    }
  }, [userId, token])

  const fetchAvailableTimes = async () => {
    if (userId && token) {
      try {
        const times = await fetchAvailableTimesFromAPI(userId, token)
        setAvailableTimes(times)
      } catch (error) {
        console.error('Error fetching available times:', error)
        message.error('An error occurred while fetching available times')
      }
    }
  }

  const fetchStudents = async () => {
    if (userId && token) {
      try {
        const studentsData = await fetchStudentsFromAPI(userId, token)
        setStudents(studentsData)
      } catch (error) {
        console.error('Error fetching students:', error)
        message.error('An error occurred while fetching students')
      }
    }
  }

  const handleChange = (value) => {
    setFormData({ ...formData, studentId: value })
    setErrors({ ...errors, studentId: '' })
  }

  const handleSelectChange = (value) => {
    setFormData({ ...formData, timeId: value })
    setErrors({ ...errors, timeId: '' })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    let formErrors = {}

    if (!formData.studentId) {
      formErrors.studentId = 'Student ID is required'
    }
    if (!formData.timeId) {
      formErrors.timeId = 'Available time is required'
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors)
      return
    }

    try {
      setIsLoading(true)
      await createCounselorSession(formData, token)
      message.success('Session created successfully')
      setFormData({ studentId: '', counselorId: userId, timeId: '' })
      fetchAvailableTimes()
    } catch (error) {
      if (error.response) {
        message.error(`Creation failed: ${error.response.data}`)
      } else {
        console.error('Error Creating Session:', error)
        message.error('An error occurred while Creating the Session.')
      }
    } finally {
      setIsLoading(false)
    }
  }

  return {
    formData,
    availableTimes,
    students,
    errors,
    isLoading,
    handleChange,
    handleSelectChange,
    handleSubmit,
  }
}

export default useCounselorSession
