import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'

const ProtectedRoute = ({ element: Component, allowedRoles, ...rest }) => {
  const userRole = localStorage.getItem('role')

  if (!allowedRoles.includes(userRole)) {
    // Redirect based on userRole
    switch (userRole) {
      case 'Student':
        return <Navigate to="/studentDashboard" />
      case 'Tutor':
        return <Navigate to="/tutorDashboard" />
      case 'Counselor':
        return <Navigate to="/counselorDashboard" />
      case 'Parent':
        return <Navigate to="/parentDashboard" />
      case 'Librarian':
        return <Navigate to="/mathematics" />
      default:
        // Default route or fallback
        return <Navigate to="/login" />
    }
  }

  return Component
}

ProtectedRoute.propTypes = {
  element: PropTypes.element.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default ProtectedRoute
