import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, message, Tooltip } from 'antd'
import 'antd/dist/antd'
import SoftTypography from 'src/components/SoftTypography'
import zoom from 'src/assets/images/Zoom-icon.png'
import { useLocation } from 'react-router-dom'

const Service = (showExtraColumns) => {
  const [showDetails, setShowDetails] = useState(true)
  const [showRequestedSessions, setShowRequestedSessions] = useState(false)
  const [meetingData, setMeetingData] = useState([])
  const [selectedMeeting, setSelectedMeeting] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isConductedModalVisible, setIsConductedModalVisible] = useState(false)
  const [loading, setIsloading] = useState(false)
  const location = useLocation()
  const userId = localStorage.getItem('userId') || ''
  const token = localStorage.getItem('token')
  const [isParticipated, setIsParticipated] = useState(null)
  const [addLoading, setAddloading] = useState(false)

  useEffect(() => {
    if (token) {
      fetchMeetings()
    }
  }, [token, userId])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const meetingId = queryParams.get('id')

    if (meetingId && meetingData.length > 0) {
      const meeting = meetingData.find((meeting) => meeting.sessionId === Number(meetingId))
      if (meeting) {
        setSelectedMeeting(meeting)
        setIsModalVisible(true)
      }
    }
  }, [location.search, meetingData])

  const fetchMeetings = async () => {
    try {
      setIsloading(true)
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/Session/Get-Counselor-Sessions?counselorId=${userId}&upcomingOnly=false`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      setMeetingData(response.data)
    } catch (error) {
      console.error('Error fetching meetings:', error)
      message.error('Error fetching meetings')
    } finally {
      setIsloading(false)
    }
  }

  const handleZoomIconClick = (record) => {
    setSelectedMeeting(record)
    setIsModalVisible(true)
  }

  const handleModalClose = () => {
    setIsModalVisible(false)
    setSelectedMeeting(null)
  }

  const handleBackButtonClick = () => {
    setShowDetails(true)
    setShowRequestedSessions(false)
  }

  const handleConductedButtonClick = (record) => {
    setSelectedMeeting(record)
    setIsConductedModalVisible(true)
  }

  const handleConductedModalClose = () => {
    setIsConductedModalVisible(false)
  }
  const handleParticipationChange = (e) => {
    setIsParticipated(e.target.value === true) // Explicitly set to true or false based on selection
  }

  const confirmMarkAsConducted = async () => {
    if (isParticipated === null) {
      message.warning("Please select 'Yes' or 'No' for participation")
      return
    }

    // Ensure `isParticipated` is explicitly a Boolean
    const participationStatus = Boolean(isParticipated)

    const requestData = {
      hostId: userId, // User ID as hostId
      sessionId: selectedMeeting.sessionId,
      attendees: [
        {
          studentId: selectedMeeting.studentId,
          isParticipated: participationStatus, // Enforcing Boolean here
        },
      ],
    }

    console.log('Request data being sent:', requestData)

    try {
      setAddloading(true)
      await axios.post(
        'https://soft.recipe4foodies.com/api/Session/Mark-As-Conducted',
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      if (participationStatus) {
        message.success('Session marked as conducted!')
      } else {
        message.success('Session marked as conducted but student did not participate.')
      }

      fetchMeetings()
      setIsConductedModalVisible(false)
    } catch (error) {
      console.error('Error marking session as conducted:', error)
      message.error('Error marking session as conducted')
    } finally {
      setAddloading(false)
    }
  }

  const initialColumns = [
    {
      title: 'Student_ID',
      dataIndex: 'studentId',
      key: 'studentId',
    },
    {
      title: 'Student Name',
      dataIndex: 'studentName',
      key: 'studentName',
    },
    {
      title: 'Email',
      dataIndex: 'studentEmail',
      key: 'studentEmail',
      render: (text) => {
        const maxLength = 8
        const truncatedText = text.length > maxLength ? `${text.slice(0, maxLength)}...` : text

        return (
          <Tooltip title={text}>
            <span>{truncatedText}</span>
          </Tooltip>
        )
      },
    },
    {
      title: 'Local Time',
      dataIndex: 'startTime',
      key: 'startTime',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'US TimeZone',
      dataIndex: 'startTimeUSTimeZone',
      key: 'startTimeUSTimeZone',
      render: (text, record) => {
        const date = new Date(text)
        const formattedDate = date.toLocaleDateString('en-US', {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
        })

        const formattedTime = date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })

        const fullTimezone = record.timezone || ''
        let timezoneAbbreviation = ''

        if (fullTimezone) {
          const timezoneMatch = fullTimezone.match(/\(([^)]+)\)/)
          timezoneAbbreviation = timezoneMatch ? timezoneMatch[1] : ''
        }

        return (
          <Tooltip title={fullTimezone || 'Timezone not available'}>
            {`${formattedDate}, ${formattedTime}${
              timezoneAbbreviation ? ` (${timezoneAbbreviation})` : ''
            }`}
          </Tooltip>
        )
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        const currentTime = new Date()
        const sessionTime = new Date(record.startTime)

        if (sessionTime < currentTime && !record.isConducted) {
          // If the session time is in the past and not conducted, show "Mark as Conducted"
          return (
            <Button onClick={() => handleConductedButtonClick(record)} type="primary">
              Mark as Conducted
            </Button>
          )
        } else if (record.isConducted) {
          // If the session is conducted, show "Conducted"
          return <span style={{ color: 'green' }}>Conducted</span>
        } else {
          // If the session is in the future or not yet conducted, show "Not Conducted"
          return <span style={{ color: 'red' }}>Not Conducted</span>
        }
      },
    },
  ]

  const extraColumns = [
    {
      title: 'Session Type',
      dataIndex: 'sessionType',
      key: 'sessionType',
    },
    {
      title: 'Session Mode',
      dataIndex: 'sessionMode',
      key: 'sessionMode',
    },
    {
      title: 'Subject',
      dataIndex: 'topic',
      key: 'topic',
    },
    {
      title: 'Link',
      dataIndex: 'startUrl',
      key: 'startUrl',
      render: (text, record) => (
        <a onClick={() => handleZoomIconClick(record)}>
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            <img src={zoom} alt="Start Link" style={{ width: '40px', height: '40px' }} />
          </SoftTypography>
        </a>
      ),
    },
  ]

  const columnsToShow = showExtraColumns ? [...extraColumns] : [...initialColumns]

  return {
    loading,
    addLoading,
    isConductedModalVisible,
    isModalVisible,
    showDetails,
    showRequestedSessions,
    columnsToShow,
    confirmMarkAsConducted,
    handleConductedModalClose,
    handleBackButtonClick,
    handleModalClose,
    meetingData,
    selectedMeeting,
    isParticipated,
    handleParticipationChange,
  }
}

export default Service
