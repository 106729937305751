import React, { useState, useEffect } from 'react'
import { Button, Modal, Select, Table, Tooltip, Typography, message } from 'antd'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import axios from 'axios'

const StudentInternship = () => {
  const userId = localStorage.getItem('userId') || ''
  const token = localStorage.getItem('token')
  const [applications, setApplications] = useState([])
  const [isloading, setIsLoading] = useState(false)
  const [selectedTeams, setSelectedTeams] = useState({}) // Track selected team members by student ID
  const teamMembers = [
    { label: 'Hamad Mahmood', value: 'Hamad Mahmood' },
    { label: 'Fatima Hamad', value: 'Fatima Hamad' },
    { label: 'Adam Lozo', value: 'Adam Lozo' },
    { label: 'Anna Caroline', value: 'Anna Caroline' },
  ]

  // Fetch existing internship applications on component mount
  useEffect(() => {
    const fetchApplications = async () => {
      try {
        setIsLoading(true)
        const response = await axios.get(
          `https://soft.recipe4foodies.com/api/InternshipApplication/Get-List-For-Counselor?counselorId=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: '*/*',
            },
          },
        )
        setApplications(response.data)
      } catch (error) {
        console.error('Error fetching applications:', error)
        message.error('Failed to fetch applications')
      } finally {
        setIsLoading(false)
      }
    }

    fetchApplications()
  }, [userId, token])

  const handleTeamAssignment = (value, record) => {
    setSelectedTeams((prev) => ({
      ...prev,
      [record.studentId]: value, // Store selected team by student ID
    }))
  }

  const columns = [
    {
      title: 'Student Id',
      dataIndex: 'studentId',
      key: 'studentId',
    },
    {
      title: 'Availability',
      dataIndex: 'availability',
      key: 'availability',
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: 'Reason',
      key: 'reasonForInternship',
      render: (text, record) => (
        <Button
          onClick={() => showApplicationModal('Reason for Internship', record.reasonForInternship)}
        >
          View Reason
        </Button>
      ),
    },
    {
      title: 'Experience',
      key: 'workExperience',
      render: (text, record) => (
        <Button onClick={() => showApplicationModal('Work Experience', record.workExperience)}>
          View Experience
        </Button>
      ),
    },
    {
      title: 'Application Date',
      dataIndex: 'applicationDate',
      key: 'applicationDate',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Eligibility',
      dataIndex: 'academicEligibility',
      key: 'academicEligibility',
    },
    {
      title: 'Assigned to Team',
      key: 'assignedToTeam',
      render: (text, record) => {
        const isEligible = record.academicEligibility === 'Yes' // Check eligibility

        return (
          <Tooltip title={!isEligible ? 'This student is not eligible' : ''}>
            <Select
              placeholder="Assign to Team"
              style={{ width: '100%' }}
              options={teamMembers}
              disabled={!isEligible} // Disable if not eligible
              onChange={(value) => handleTeamAssignment(value, record)}
            />
          </Tooltip>
        )
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        const isButtonDisabled = !selectedTeams[record.studentId] // Check if button should be disabled

        return (
          <Tooltip title={isButtonDisabled ? 'Select team member' : ''}>
            <Button
              type="primary"
              disabled={isButtonDisabled} // Disable button if no team member is selected
              onClick={() => handleSend(record)} // Send action to assign team
            >
              Send
            </Button>
          </Tooltip>
        )
      },
    },
  ]

  const showApplicationModal = (title, content) => {
    Modal.info({
      title: title,
      content: (
        <div>
          {content ? (
            <Typography.Text>{content}</Typography.Text>
          ) : (
            <Typography.Text>No information provided</Typography.Text>
          )}
        </div>
      ),
      onOk() {
        // Optionally reset modal state
      },
      centered: true,
    })
  }

  const handleSend = async (record) => {
    const selectedTeam = selectedTeams[record.studentId] // Get the selected team for the student

    if (!selectedTeam) {
      message.error('Please select a team member first.')
      return
    }

    const payload = {
      internshipApplicationId: record.internshipApplicationId, // Send internshipApplicationId
      teamName: selectedTeam, // Send the selected team name
    }

    try {
      const response = await axios.post(
        'https://soft.recipe4foodies.com/api/InternshipApplication/Assign-Team',
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token for authentication
            'Content-Type': 'application/json',
          },
        },
      )
      if (response.status === 200) {
        message.success(`Team assigned successfully for student ID: ${record.studentId}`)
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          message.error(`Failed: ${error.response.data}`)
        } else {
          message.error(`Submission failed: ${error.response.data}`)
        }
      } else {
        console.error('Error assigning team', error)
        message.error('Failed to assign team. Please try again.')
      }
    }
  }

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: 'relative' }}>
              <h5>Student Internship</h5>
            </div>
            <div className="card-body">
              <Table
                className="table-responsive"
                dataSource={applications}
                loading={isloading}
                columns={columns}
                rowKey="stdId"
              />
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default StudentInternship
