import React, { createContext, useState, useContext } from 'react'
import PropTypes from 'prop-types' // Import PropTypes

const NavbarVisibilityContext = createContext()

export const NavbarVisibilityProvider = ({ children }) => {
  const [isNavbarVisible, setIsNavbarVisible] = useState(true)

  return (
    <NavbarVisibilityContext.Provider value={{ isNavbarVisible, setIsNavbarVisible }}>
      {children}
    </NavbarVisibilityContext.Provider>
  )
}

// Add prop types validation
NavbarVisibilityProvider.propTypes = {
  children: PropTypes.node.isRequired, // Validate children prop
}

export const useNavbarVisibility = () => {
  return useContext(NavbarVisibilityContext)
}
