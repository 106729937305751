import React, { useState, useEffect } from 'react'
import { Table, message, Button } from 'antd'
import 'antd/dist/antd'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import CIcon from '@coreui/icons-react'
import { cilTrash } from '@coreui/icons'
import SoftTypography from 'src/components/SoftTypography'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker'
import './UpdateSchedule.scss' // Import your CSS file for additional styles

export default function UpdateSchedule() {
  const userId = localStorage.getItem('userId') || ''
  const token = localStorage.getItem('token')
  const [dateTime, setDateTime] = useState(null)
  const [scheduleData, setScheduleData] = useState([])
  const [isFormVisible, setIsFormVisible] = useState(false)
  const [sessionCount, setSessionCount] = useState(0)
  const [manualScheduleData, setManualScheduleData] = useState([])
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
  const headers = token ? { Authorization: `Bearer ${token}` } : {}

  useEffect(() => {
    scheduledataSource()
  }, [token])

  const scheduledataSource = () => {
    fetch(
      `https://soft.recipe4foodies.com/api/AvailabilityTime/Counselor/Get-Two-Weeks-AvailableDates?counselorId=${userId}`,
      {
        headers: headers,
      },
    )
      .then((res) => res.json())
      .then((data) => {
        setScheduleData(data)
      })
  }

  const submitSchedule = () => {
    const requestBody = {
      userId: userId,
      availableTimes: manualScheduleData.map((entry) => entry.availableTime),
    }

    fetch('https://soft.recipe4foodies.com/api/AvailabilityTime/Counselor/Add-AvailableDates', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to submit schedule data')
        }
        message.success('Schedule data submitted successfully')
        setManualScheduleData([]) // Clear the selected dates table after submission
        scheduledataSource()
        setIsFormVisible(false) // Hide the form and selected dates table
      })
      .catch((error) => {
        console.error('Error submitting schedule data:', error)
        message.error('Failed to submit schedule data. Please try again.')
      })
  }

  const handleDeleteBiWeeklySchedule = (timeId) => {
    fetch(
      `https://soft.recipe4foodies.com/api/AvailabilityTime/Counselor/Delete-Time?timeId=${timeId}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
      .then((response) => response.text()) // Get response as text
      .then((data) => {
        if (data === 'Counselor time deleted successfully') {
          setScheduleData((prevData) => prevData.filter((item) => item.timeId !== timeId))
          message.success('Counselor time deleted successfully')
        } else {
          message.error('Failed to delete counselor time')
        }
      })
      .catch((error) => {
        console.error('Error deleting counselor time:', error)
        message.error('Failed to delete counselor time. Please try again.')
      })
  }

  const columnsBiWeeklySchedule = [
    {
      title: 'Counselor ID',
      dataIndex: 'userId',
      key: 'userId',
    },
    {
      title: 'Start Date Time',
      dataIndex: 'availableTime',
      key: 'availableTime',
      render: (text) => moment.utc(text).local().format('MM/DD/YYYY h:mm A'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <div style={{ cursor: 'pointer' }}>
          <SoftTypography
            component="a"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            onClick={() => handleDeleteBiWeeklySchedule(record.timeId)}
          >
            <CIcon size="12px" icon={cilTrash} />
          </SoftTypography>
        </div>
      ),
    },
  ]

  const columnsSelectedDates = [
    {
      title: 'Counselor ID',
      dataIndex: 'userId',
      key: 'userId',
    },
    {
      title: 'Start Date Time',
      dataIndex: 'availableTime',
      key: 'availableTime',
      render: (text) => moment.utc(text).local().format('MM/DD/YYYY h:mm A'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record, index) => (
        <div style={{ cursor: 'pointer' }}>
          <SoftTypography
            component="a"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            onClick={() => handleRemoveSession(index)}
          >
            <CIcon size="12px" icon={cilTrash} />
          </SoftTypography>
        </div>
      ),
    },
  ]

  const handleDateTimeChange = (date) => {
    setDateTime(date)
  }

  const handleAddButtonClick = () => {
    if (!dateTime) {
      message.error('Please select a date and time.')
      return
    }

    const selectedDate = moment(dateTime)
    const currentDate = moment()

    if (selectedDate.isBefore(currentDate)) {
      message.error('Selected date cannot be earlier than the current date.')
      return
    }

    const newData = {
      key: sessionCount,
      userId: userId,
      availableTime: moment(dateTime).utc().format('YYYY-MM-DDTHH:mm:ssZ'),
    }

    setManualScheduleData((prevData) => [...prevData, newData])
    setDateTime(null)
    setSessionCount(sessionCount + 1)

    message.success('Data added to Selected Dates')
  }

  const handleRemoveSession = (index) => {
    const updatedData = [...manualScheduleData]
    updatedData.splice(index, 1)
    setManualScheduleData(updatedData)
    message.success('Session removed from Selected Dates')
  }

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          {!isFormVisible ? (
            <div className="card">
              <div className="card-header" style={{ position: 'relative' }}>
                <div className="row">
                  <div className="col-md-6">
                    <h5>Bi-weekly Schedule</h5>
                  </div>
                  <div className="col-md-6 text-end">
                    <Button type="primary" onClick={() => setIsFormVisible(true)}>
                      Add
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Table
                  className="table-responsive"
                  bordered
                  dataSource={scheduleData}
                  columns={columnsBiWeeklySchedule}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="card">
                <div className="card-header" style={{ position: 'relative' }}>
                  <h5>Enter record for 2 weeks</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                          style={{ fontSize: 'large' }}
                        >
                          Set Date Time
                        </label>
                        <br />
                        <DatePicker
                          selected={dateTime}
                          onChange={handleDateTimeChange}
                          onCalendarOpen={() => setIsDatePickerOpen(true)}
                          onCalendarClose={() => setIsDatePickerOpen(false)}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={60}
                          dateFormat="MM/dd/yyyy h:mm aa"
                          placeholderText="Select date and time"
                          className="custom-date"
                        />
                      </div>
                    </div>
                    <div>
                      <Button type="primary" onClick={handleAddButtonClick}>
                        Add
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="card">
                <div className="card-header" style={{ position: 'relative' }}>
                  <div className="row">
                    <div className="col-md-6">
                      <h5>Selected Dates</h5>
                    </div>
                    <div className="col-md-6 text-end">
                      <Button type="primary" onClick={submitSchedule}>
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <Table
                    className={`table-responsive ${isDatePickerOpen ? 'hide-table' : ''}`}
                    bordered
                    dataSource={manualScheduleData}
                    columns={columnsSelectedDates}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </DashboardLayout>
  )
}
