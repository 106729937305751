import React, { useState } from 'react'
import { Card, Typography, Button, Modal } from 'antd'
import { MailOutlined } from '@ant-design/icons'
import { Link, useLocation } from 'react-router-dom'

const { Title, Text } = Typography

const EmailConfirmation = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const location = useLocation()
  const responseData = location.state || {}

  console.log('Received responseData:', responseData)

  const showModal = () => setIsModalVisible(true)
  const handleCancel = () => setIsModalVisible(false)
  const openGmail = () => window.open('https://mail.google.com', '_blank')
  const openOutlook = () => window.open('https://outlook.live.com', '_blank')
  const openHotmail = () => window.open('https://outlook.live.com/owa/', '_blank')

  if (!responseData.studentName || !responseData.studentEmail || !responseData.platformEmail) {
    return <div>Loading...</div>
  }

  return (
    <Card
      style={{ maxWidth: '600px', margin: 'auto', padding: '20px', marginTop: '20px' }}
      bordered={false}
    >
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <MailOutlined style={{ fontSize: '48px', color: '#1890ff' }} />
      </div>
      <Title level={3} style={{ textAlign: 'center', marginBottom: '20px' }}>
        Email Confirmation
      </Title>
      <Text>Dear {responseData.studentName},</Text>
      <br />
      <br />
      <Text>
        Thank you for signing up for{' '}
        <Link to="https://1journey.ai/" target="_blank">
          1journey.ai
        </Link>
        . We’re excited to have you on board.
      </Text>
      <br />
      <br />
      <Text>
        Please check your inbox or spam folder at{' '}
        <a href={`mailto:${responseData.studentEmail}`}>{responseData.studentEmail}</a> for an email
        from <a href={`mailto:${responseData.platformEmail}`}>{responseData.platformEmail}</a> with
        instructions to verify your email address and access your account. If you do not verify your
        email address within one hour, your token will expire, and you will be unable to access your
        account.
      </Text>
      <br />
      <br />
      {responseData.isParentAdded ? (
        <Text>
          Your parent with email{' '}
          <a href={`mailto:${responseData.parentEmail}`}>{responseData.parentEmail}</a> has been
          connected with you. Please inform them to check their email for instructions on setting up
          their account and completing the verification process.
        </Text>
      ) : (
        <Text>
          The parent with email{' '}
          <a href={`mailto:${responseData.parentEmail}`}>{responseData.parentEmail}</a> already has
          an account with us and is now connected with you.
        </Text>
      )}
      <br />
      <br />
      <Text>
        Thank you once again for registering for our{' '}
        <Link to="https://1journey.ai/" target="_blank">
          1journey.ai
        </Link>
        . In case of any questions, contact the support team at{' '}
        <a href={`mailto:${responseData.platformEmail}`}>{responseData.platformEmail}</a>.
      </Text>
      <br />
      <br />
      <Text>
        Best regards,
        <br />
        <Link to="https://1journey.ai/" target="_blank">
          1journey.ai
        </Link>
      </Text>
      <br />
      <div style={{ textAlign: 'center' }}>
        <Button type="primary" onClick={showModal}>
          Go to Mail
        </Button>
      </div>
      <Modal
        title="Select Email Provider"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={420}
      >
        <Button type="primary" style={{ marginRight: '10px' }} onClick={openGmail}>
          Open Gmail
        </Button>
        <Button type="primary" style={{ marginRight: '10px' }} onClick={openOutlook}>
          Open Outlook
        </Button>
        <Button type="primary" onClick={openHotmail}>
          Open hot mail
        </Button>
      </Modal>
    </Card>
  )
}

export default EmailConfirmation
