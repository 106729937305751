import React from 'react'
import PropTypes from 'prop-types'

// @mui material components
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import PageLayout from 'src/StudentNavLayouts/LayoutContainers/PageLayout'

function CoverLayout({ color, header, title, description, image, top, children }) {
  return (
    <PageLayout background="white">
      <Grid
        container
        justifyContent="center"
        sx={{
          minHeight: '75vh',
          margin: 0,
          marginTop: '100px',
        }}
      >
        <Grid item xs={11} sm={8} md={5} xl={3}>
          <Box>
            <Box pt={3} px={3}>
              {!header ? (
                <>
                  <Box mb={1}>
                    <Typography variant="h3" fontWeight="bold" color={color}>
                      {title}
                    </Typography>
                  </Box>
                  <Typography variant="body2" fontWeight="regular" color="text">
                    {description}
                  </Typography>
                </>
              ) : (
                header
              )}
            </Box>
            <Box p={3}>{children}</Box>
          </Box>
        </Grid>
      </Grid>
    </PageLayout>
  )
}

// Setting default values for the props of CoverLayout
CoverLayout.defaultProps = {
  header: '',
  title: '',
  description: '',
  color: 'info',
  top: 20,
}

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
    'light',
  ]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  top: PropTypes.number,
  children: PropTypes.node.isRequired,
}

export default CoverLayout
