import colors from 'src/assets/theme/base/colors'

const { dark } = colors

const formLabel = {
  styleOverrides: {
    root: {
      color: dark.main,
    },
  },
}

export default formLabel
