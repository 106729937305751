import React, { useState, useEffect } from 'react'
import { Form, message, Button } from 'antd'
import moment from 'moment'
import {
  getRecommendations,
  getCounselorStudents,
  getCourses,
  submitRecommendation,
} from './Service' // Import your API functions

export const useRecommendationData = () => {
  const userId = localStorage.getItem('userId') || ''
  const token = localStorage.getItem('token')

  const [resultData, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [students, setStudents] = useState([])
  const [courses, setCourses] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [viewMode, setViewMode] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [form] = Form.useForm()

  useEffect(() => {
    if (token) {
      handleGetRecommendations()
      fetchStudents()
      fetchCourses()
    }
  }, [token])

  const handleGetRecommendations = async () => {
    try {
      setLoading(true)
      const response = await getRecommendations(userId, token)
      setData(response.data)
    } catch (error) {
      console.error('Error fetching recommendations:', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchStudents = async () => {
    try {
      const response = await getCounselorStudents(userId, token)
      setStudents(response.data)
    } catch (error) {
      console.error('Error fetching students:', error)
    }
  }

  const fetchCourses = async () => {
    try {
      const response = await getCourses()
      setCourses(response.data)
    } catch (error) {
      console.error('Error fetching courses:', error)
    }
  }

  const handleFormSubmit = async (values) => {
    setSubmitLoading(true)
    try {
      const payload = {
        counselorId: parseInt(userId),
        studentId: values.studentId,
        sessionsCount: values.sessionsCount,
        subject: values.subject,
        sessionType: values.sessionType,
        recommendationNote: values.recommendationNote,
      }

      await submitRecommendation(payload, token)

      message.success('Recommendation submitted successfully!')
      setShowForm(false)
      form.resetFields()
      handleGetRecommendations()
    } catch (error) {
      console.error('Error submitting recommendation:', error)
      message.error('Failed to submit recommendation. Please try again.')
    } finally {
      setSubmitLoading(false)
    }
  }

  const handleViewDetails = (record) => {
    setSelectedRecord(record)
    setViewMode(true)
  }

  const handleBackToTable = () => {
    setViewMode(false)
    setSelectedRecord(null)
  }

  const columns = [
    { title: 'Student Id', dataIndex: 'studentId', key: 'studentId' },
    { title: 'Sessions Count', dataIndex: 'sessionsCount', key: 'sessionsCount' },
    { title: 'Subject', dataIndex: 'subject', key: 'subject' },
    { title: 'Session Type', dataIndex: 'sessionType', key: 'sessionType' },
    {
      title: 'Date Time',
      dataIndex: 'dateTime',
      key: 'dateTime',
      render: (text) => moment(text).format('MMM DD, yyyy HH:mm'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        if (text === 'Granted' && record.paymentSuccess) {
          return 'Paid'
        } else if (text === 'Granted' && !record.paymentSuccess) {
          return 'Granted'
        } else {
          return text
        }
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <>
          <Button onClick={() => handleViewDetails(record)}>View Details</Button>
        </>
      ),
    },
  ]

  return {
    resultData,
    loading,
    students,
    courses,
    showForm,
    submitLoading,
    viewMode,
    selectedRecord,
    form,
    handleFormSubmit,
    handleBackToTable,
    columns,
    setShowForm,
    setSubmitLoading,
    setViewMode,
  }
}
