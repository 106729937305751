import React, { useState, useEffect } from 'react'
import { Button, Table, Tooltip, message } from 'antd'
import axios from 'axios'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import { useNavigate } from 'react-router-dom'

const NewStudents = () => {
  const userId = localStorage.getItem('userId') || ''
  const [students, setStudents] = useState([])
  const token = localStorage.getItem('token')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await axios.get(
          `https://soft.recipe4foodies.com/api/CounselorStudents/GetNewStudentsByCounselor?counselorId=${userId}`,
          { headers },
        )
        setStudents(response.data)
      } catch (error) {
        console.error('Error fetching students:', error)
        message.error('Error fetching students')
      }
    }

    fetchStudents()
  }, [userId, token])

  const columns = [
    {
      title: 'Student ID',
      dataIndex: 'studentId',
      key: 'studentId',
    },
    {
      title: 'Parent ID',
      dataIndex: 'parentId',
      key: 'parentId',
    },
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text) => (text ? text : 'N/A'),
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      render: (text) => (text ? text : 'N/A'),
    },
    {
      title: 'Joined Date',
      dataIndex: 'joinedDateTime',
      key: 'joinedDateTime',
      render: (date) =>
        date !== '0001-01-01T00:00:00' ? new Date(date).toLocaleDateString() : 'N/A',
    },
    {
      title: 'Create Session',
      key: 'createSession',
      render: (record) => {
        const hasCounselorSessions = record.counselorSessions > 0
        return (
          <Tooltip title={!hasCounselorSessions ? 'Session Expired' : ''}>
            <Button
              type="primary"
              onClick={() => handleCreateSession(record.studentId)} // Handle click
              disabled={!hasCounselorSessions}
            >
              Create Session
            </Button>
          </Tooltip>
        )
      },
    },
  ]

  const navigate = useNavigate()
  const handleCreateSession = (studentId) => {
    navigate(`/counselorSession?studentId=${studentId}`)
  }

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: 'relative' }}>
              <b>New Students</b>
            </div>
            <div className="card-body">
              <Table
                className="table-responsive"
                dataSource={students}
                columns={columns}
                bordered
                rowKey="studentId"
              />
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default NewStudents
