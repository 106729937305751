import React, { useState, useEffect } from 'react'
import { Table, Spin, Button, message, Modal, List, Typography } from 'antd'
import 'antd/dist/antd'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useNavbarVisibility } from '../../Common/NavbarVisibilty'

const Essay = () => {
  const counselorId = localStorage.getItem('userId')
  const token = localStorage.getItem('token')
  const [isloading, setIsLoading] = useState(false)
  const [applicationsData, setApplicationsData] = useState([])
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false)
  const [selectedFeedback, setSelectedFeedback] = useState(null)
  const { setIsNavbarVisible } = useNavbarVisibility()
  const navigate = useNavigate()

  useEffect(() => {
    fetchStudentApplications()
  }, [counselorId, token])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'essayReviewApplicationId',
      key: 'essayReviewApplicationId',
    },
    {
      title: 'Student Id',
      dataIndex: 'studentId',
      key: 'studentId',
    },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    {
      title: 'Application Submitted Date',
      dataIndex: 'applicationSubmissionDate',
      key: 'applicationSubmissionDate',
      render: (date) => new Date(date).toLocaleString(),
    },
    {
      title: 'Feedback_Given',
      dataIndex: 'feedbacks',
      width: '15%',
      render: (feedbacks) =>
        feedbacks && feedbacks.length > 0 ? (
          <Button size="small" onClick={() => showFeedbackModal(feedbacks)}>
            See Feedback
          </Button>
        ) : (
          'N/A'
        ),
    },
    {
      title: 'Views Detail',
      key: 'view',
      render: (text, record) => (
        <Button onClick={() => gotoViewDetail(record.essayReviewApplicationId)}>
          Views Detail
        </Button>
      ),
    },
  ]

  const gotoViewDetail = (essayReviewApplicationId) => {
    navigate('/EssayReview', { state: { essayReviewApplicationId } })
  }

  const fetchStudentApplications = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/EssayApplication/Get-Counselor-Application-List?counselorId=${counselorId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      )
      setApplicationsData(response.data)
    } catch (error) {
      message.error('Failed to fetch student applications')
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  // Function to handle showing the feedback modal
  // const showFeedbackModal = (feedback) => {
  //   setSelectedFeedback(feedback)
  //   setIsFeedbackModalVisible(true)
  // }

  const showFeedbackModal = (feedbacks) => {
    Modal.info({
      title: 'Provided Feedback',
      content: (
        <div>
          {feedbacks && feedbacks.length > 0 ? (
            <List
              bordered
              dataSource={feedbacks}
              renderItem={(feedbackObj, index) => (
                <List.Item key={index}>
                  <Typography.Text type="secondary">
                    Essay ID: {feedbackObj.essayId}
                  </Typography.Text>
                  <br />
                  <Typography.Text strong>Feedback:</Typography.Text>{' '}
                  {feedbackObj.feedback ? feedbackObj.feedback : 'No feedback provided'}
                </List.Item>
              )}
            />
          ) : (
            <Typography.Text>No feedback available</Typography.Text>
          )}
        </div>
      ),
      onOk() {
        // Reset any state here if needed
      },
      centered: true,
    })
  }

  // Function to handle closing the feedback modal
  const handleModalClose = () => {
    setIsFeedbackModalVisible(false)
    setSelectedFeedback(null)
    setIsNavbarVisible(false)
  }

  return (
    <>
      <div>
        {isloading ? (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Spin size="large" />
          </div>
        ) : (
          <>
            <Table
              className="table-responsive"
              bordered
              columns={columns}
              dataSource={applicationsData}
            />
          </>
        )}
      </div>

      {/* Modal to display feedback */}
      <Modal
        title="Feedback"
        visible={isFeedbackModalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="close" onClick={handleModalClose}>
            Close
          </Button>,
        ]}
      >
        <p>{selectedFeedback ? selectedFeedback : 'No feedback available'}</p>
      </Modal>
    </>
  )
}

export default Essay
